export const ewalletLatest = {
  title: "E-Wallet",
  description: "Simplify Your Spending with Our E-Wallet Solution",
  pageUrl: "/work/e-wallet-app-design",
  videoUrl: "",
  imagehomeUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/ewallet-home-0224.png",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/ewallet-work-0224.png",
};

export const futbankLatest = {
  title: "Fut Bank",
  description: "Creating a brand-agnostic banking app for the Future.",
  pageUrl: "/work/fut-bank-app",
  videoUrl: "",
  imagehomeUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/fut-home-0224.png",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/fut-work-0224.png",
};

export const sunnybeeLatest = {
  title: "SunnyBee",
  description:
    "Taking a physical retail store to the Future and meeting its customers there.",
  pageUrl: "/work/sunnybee-mobile-app",
  videoUrl: "",
  imagehomeUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/sunnybee-home-0224.png",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/sunnybee-work-0224.png",
};

export const flyet = {
  title: "Flyet",
  description: "Creating a Seamless Travel Booking Experience.",
  pageUrl: "/work/flyet",
  videoUrl: "",
  imagehomeUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/flyet-home-cover.png",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2024/flyet-work-cover.png",
};

export const mahasaghar = {
  title: "Mahāsa Ghar",
  description: "Building a 3D Marketplace for the Mindful Shopper",
  pageUrl: "/work/mahasaghar",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/mahasaghar-cover.png",
  imagehomeUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/mahasaghar-cover-home.png",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/mahasaghar-cover.png",
};

export const sunnybee = {
  title: "SUNNYBEE MOBILE APPLICATION",
  description:
    "Taking a physical store online and bringing their customers to them.",
  pageUrl: "/work/sunnybee-mobile-app",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a9fff185-15088-1.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/d9f1558e-sunnybee-mob.png",
};

export const asianpaints = {
  title: "ASIAN PAINTS",
  description: "Adding Asian Paints to our brand palette.",
  pageUrl: "/work/asian-paints",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/0c7d6eca-asian-paints.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f14db6ce-52656-1.png",
};

export const intellect = {
  title: "INTELLECT : ANNUAL REPORT",
  description: "The world's no.2 in digital banking, banks on us.",
  pageUrl: "/work/intellect",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/9b505d55-intellect-c.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e3a67bfb-151401-1.png",
};

export const freshey = {
  title: "FRESHEY’S",
  description: "Your kitchen companion that makes every day easy!",
  descr: "Serving up a brand new experience",
  pageUrl: "/work/freshey",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/fd5d1f4a-freshey-c.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/a5bdb895-fresheys.jpg",
};

export const vinveli = {
  title: "VINVELI",
  description: "Towards a smarter and safer future.",
  pageUrl: "/work/vinveli",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/b13b5014-vinveli_logo-animaiton-c.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e7291347-fghfh-1.png",
};

export const abbys = {
  title: "ABBYS",
  description: "A quick pick me up.",
  pageUrl: "/work/abbys",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/539910c3-delivery.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/02/155d4c85-hgghfgj-1.png",
};

export const authorcafe = {
  title: "AUTHORCAFÉ",
  description: "Redesign for a website that’s designed for academic research.",
  pageUrl: "/work/authorcafe-website-development",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ec7bb71d-author-cafe_trim-1.mp4",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7a3e0670-auhtorcafe-1.png",
};

export const mehta = {
  title: "MEHTA JEWELLERY",
  description: "Showcasing a heritage jewellery brand in the digital age.",
  pageUrl: "/work/mehta-jewellery-social-media",
  videoUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/98aef586-mehta-c.mp4",
};

export const mypersona = {
  title: "MY PERSONA",
  description: "Redesigning a website for creators of authentic leaders.",
  pageUrl: "/work/mypersona",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/7534c8e1-my-persona-cover.png",
};

export const bayleaf = {
  title: "BAYLEAF",
  description: "Social media content that’ll leave you hungry for more.",
  pageUrl: "/work/bayleaf",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/81d96f3c-cover-1.png",
};

export const sequencelounge = {
  title: "SEQUENCE LOUNGE",
  description:
    "Customizing an e-commerce website for customized designer clothing.",
  pageUrl: "/work/sequence-lounge",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/ededfad9-sequence-longue-1-1.png",
};

export const airportzo = {
  title: "AIRPORTZO",
  description:
    "A seamless online experience for a seamless travel experience provider",
  pageUrl: "/work/airportzo",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/573bb441-airportzo-cover.png",
};

export const writerscafe = {
  title: "WRITERS CAFE",
  description: "The write kind of branding.",
  pageUrl: "/work/writers-cafe",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2afd00c5-ghdhd-1.png",
};

export const privateearth = {
  title: "PRIVATE EARTH",
  description: "A welcoming site for luxury, natural builders.",
  pageUrl: "/work/private-earth",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/df602426-private-earth-cover.png",
};

export const ewallet = {
  title: "E – WALLET",
  description: "Creating an app the whole family can bank on.",
  pageUrl: "/work/e-wallet-app-design",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/fab9e2e4-dfhhfgh-1.png",
};

export const lexotique = {
  title: "L’EXOTIQUE",
  description: "Rethinking Food Miles For L’exotique.",
  pageUrl: "/work/lexotique",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/876d4554-26111-1.png",
};

export const mehtajewellery = {
  title: "MEHTA JEWELLERY DIGITAL CAMPAIGN",
  description: "Showcasing a heritage jewellery brand in the digital age.",
  pageUrl: "/work/mehta-jewellery-social-media",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5aef8770-mehta.jpg",
};

export const bharathihomes = {
  title: "BHARATHI HOMES",
  description: "Not your average website for not your average builders.",
  pageUrl: "/work/bharathi-homes-website-design",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/bf934a3e-ggg.png",
};

export const curiohh = {
  title: "CURIOHH",
  description: "For the everyday adventurer.",
  pageUrl: "/work/curiohh",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8daaccfd-curiohhg-1.png",
};

export const vinveliweb = {
  title: "VINVELI",
  description:
    "Bringing the best of the international firearms online, to India.",
  pageUrl: "/work/vinveli-website",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/d4d25db9-vinveli-cover.png",
};

export const gbr = {
  title: "GBR TMT",
  description: "Creating a strong digital foundation for GBR Metals.",
  pageUrl: "/work/gbr-tmt",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/c91b7ad3-gbr-app-website-cover.png",
};

export const waycool = {
  title: "WAYCOOL",
  description: "Re-imagining India’s food supply chain.",
  pageUrl: "/work/waycool",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/4ded3e0a-waycool.jpg",
};

export const thexanadu = {
  title: "THE XANADU",
  description: "Redefining vacations, one click at a time",
  pageUrl: "/work/the-xanadu",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/a25eb286-000000000.png",
};

export const oswinply = {
  title: "OSWIN PLY",
  description: "A doorway to more customers for Oswin Ply.",
  pageUrl: "/work/oswin-ply",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/36f4d27f-dsga-1.png",
};

export const vinitahospital = {
  title: "VINITA HOSPITAL",
  description: "Creating an icon for an iconic brand.",
  pageUrl: "/work/vinita-hospital",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/f33dc04c-hdsf-1.png",
};

export const desidi = {
  title: "DESI DI",
  description: "Serving up a fresh look for Indian food.",
  pageUrl: "/work/desi-di",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/05/c756c738-desi-1.png",
};

export const kitchenji = {
  title: "KITCHENJI",
  description: "Your kitchen companion that makes every day easy!",
  pageUrl: "/work/kitchen-ji",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/60d75e9c-sfafafa-1.png",
};

export const shuddha = {
  title: "SHUDDHA",
  description: "The promise of purity.",
  pageUrl: "/work/shuddha",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d9631ac3-gfghj-1.png",
};

export const curiohhtravel = {
  title: "CURIOHH – TRAVEL MERCHANDISE",
  description: "Inspiring a new generation of adventurers.",
  pageUrl: "/work/curiohh-travel-merchandise-branding",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9a2bc3f4-htdshsd-1.png",
};

export const amelies = {
  title: "AMELIES",
  description: "A brand new purpose. A brand new expression.",
  pageUrl: "/work/amelies",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/9b5f1554-fhdhdsshd-1.png",
};

export const kokumstore = {
  title: "KOKUM STORE",
  description: "Designing an ecommerce site for contemporary designer wear.",
  pageUrl: "/work/kokum-store",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/4557de6b-kokum-cover.png",
};

export const sunnybeehoney = {
  title: "SUNNY BEE – HONEY",
  description: "Good design for good health.",
  pageUrl: "/work/sunny-bee-honey-packaging",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/ca362264-65454-1.png",
};

export const hilton = {
  title: "HILTON",
  description: "Creating memorable communication over the years.",
  pageUrl: "/work/hilton",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cd2eac14-hilton.jpg",
};

export const sunnybeebranding = {
  title: "SUNNY BEE",
  description: "Gathering goodness for this neighbourhood grocery store.",
  pageUrl: "/work/sunny-bee-identity",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/8a5d16ac-logo-sunnybee.jpg",
};

export const loanoriginationsystem = {
  title: "LOAN ORIGINATION SYSTEM",
  description:
    "Developing a smart banking framework that clients and banks can use with ease.",
  pageUrl: "/work/loan-origination-system",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/03ff0e50-54516.png",
};

export const sunnybeebread = {
  title: "SUNNY BEE – BREAD PACKAGING",
  description: "Possibly the best thing sliced bread!",
  pageUrl: "/work/sunny-bee-bread-packaging",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/08/35bcb7cb-sb-bread.png",
};

export const transformativedigital = {
  title: "TRANSFORMATIVE DIGITAL HR",
  description:
    "Human resources software assisting in all HR related functions.",
  pageUrl: "/work/transformative-digital-hr",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/cfac1e8e-fghfg-1.png",
};

export const aniva = {
  title: "ANIVA",
  description: "Giving healthy drinks a whole new look.",
  pageUrl: "/work/aniva-identity",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7e5b5ea7-jfdjf-1.png",
};

export const vapho = {
  title: "VAPHO",
  description:
    "Smart, quirky communication for a food brand with something different to say.",
  pageUrl: "/work/vapho",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4245f4f6-89222-1.png",
};

export const futbank = {
  title: "FUT BANK",
  description: "Creating a brand-agnostic banking app you can bank on.",
  pageUrl: "/work/fut-bank-app",
  imageworkUrl:
    "https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/981a7438-fgjdjfg-1.png",
};
