import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { bharathihomes, sunnybee, loanoriginationsystem } from '../Model/Project'


const Privateearth = () => {
  return (
    <>

        <Header />

        <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>Private Earth</h2>
                <h3 className='text-animate'>A welcoming site for luxury, natural builders.</h3>
            </div>
        </div>

        <div className="private-earth-ban-bg">
            <div className="container private-earth-ban text-animate"              >
                <img className="img-fluid private-earth-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/fcbbee00-pehq1-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="container vinveli-obj">
            <div className="row">
                <div className="vinveli-obj-cont">
                    <h5 className="border-bottom text-animate"         >OBJECTIVE</h5>
                    <p className='text-animate'>Luxury nature spaces are Private Earth’s forte, builders who understand the delicate balance between nature and built environments, between their client’s vision and real world practicality. For them, we chose to create a responsive website that truly reflected their design sensibilities while showcasing their beautiful properties in all their natural splendour. Using clean colours, simple typography and easy navigation tools, the website is an extension of their minimalistic yet luxurious aesthetic. It takes viewers through a journey of the design process, from their approach to the final, stunning result.</p>
                </div>
            </div>
        </div>

        <div className="vinveli-wf">
            <div className="container vinveli-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
            </div>

            <div className="container text-animate"              >
                <img className="img-fluid vinveli-wf-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/82cf4d19-pe02-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="private-earth-img-bg">
            <div className="container text-animate"         >
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/44da2f55-pe03-1.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="container private-earth-img text-animate"              >
            <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/85e5157b-pe04-1.png" alt="vinveli-banner" />
        </div>

        <div className="private-earth-img-bgb">
            <div className="container text-animate"         >
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/8d75f61f-pehq3-1-1315x1536.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={bharathihomes.title}
                            description={bharathihomes.description}
                            pageUrl={bharathihomes.pageUrl}
                            imageworkUrl={bharathihomes.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageworkUrl={sunnybee.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={loanoriginationsystem.title}
                            description={loanoriginationsystem.description}
                            pageUrl={loanoriginationsystem.pageUrl}
                            imageworkUrl={loanoriginationsystem.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Privateearth