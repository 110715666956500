import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { freshey, kitchenji, shuddha } from '../Model/Project'

const Lexotique = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head">
                <h2 className='text-animate'>L’exotique​</h2>
                <h3 className='text-animate'>Rethinking Food Miles For L’exotique.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/ed204045-lexotique-video-fdor-social-media-2-1-1.png" alt="intellect-banner" />
        </div>

        <div className="container position-relative">
            <div className="text-center lexotique-cont">
                <img className="img-fluid bayleaf-ban-img lexotique-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/00623d99-logo-3-1.png" alt="intellect-banner" />

                <p>L’exotique is a premium fresh fruit brand to be introduced across India and the UAE<br className="d-none d-lg-block" /> (potentially other markets in the future) known for its quality, distinctiveness of variety, and<br className="d-none d-lg-block" /> traceability, achieved by sourcing from the choicest of orchards across the globe and moving<br className="d-none d-lg-block" /> the product through state-of-the-art supply chains.</p>

                <img className="img-fluid bayleaf-ban-img w-50" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d2158532-groufdsfdp-1.png" alt="intellect-banner" />
            </div>
        </div>

        <div className="container position-relative">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/4a934b5e-logo-grid-2.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/3e75c40f-logo-grid-1.png" alt="intellect-banner" />
                </div>
            </div>

            <div className="text-animate">
                <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d3d191b8-film.mp4' muted controls preload="auto" loop playsInline controlsList="nodownload" />
            </div>

            <div className="row bayleaf-ban-img">
                <div className="col-lg-4 col-md-4">
                    <img className="img-fluid lexotique-padd" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e4bf08f2-lexotique-video-for-social-media-6-1-1.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img className="img-fluid lexotique-padd" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2cdc751a-lexotique-video-for-social-media-8-1-1.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-4 col-md-4">
                    <img className="img-fluid lexotique-padd" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c2a11850-lexotique-video-for-social-media-3-1-1.png" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="lexotique-bg">
            <div className="container lexotique-bg-para">
                <p>From brand name and identity design to packaging, social media and film, we worked with key stakeholders to strategize, implement and grow the brand.</p>
            </div>
        </div>

        <div className="container">
            <div className="row bayleaf-ban-img">
                <div className="col-lg-6 col-md-6 p-0">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/14fa56e1-waycool-leexotique.jpg" alt="intellect-banner" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/947d8143-apple-copy.jpg" alt="intellect-banner" />
                </div>

                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/99f35379-avocado.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b7730a65-avacado-skating.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>

                <div className="col-lg-6 col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c739bfa1-avacado-clap.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/7a5f28c8-dragon-fruits_type_05.jpg" alt="intellect-banner" />
                </div>
            </div>

            <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/84ea7552-ssasdf-1.png" alt="intellect-banner" />

            <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/f3cbe447-faad-1.png" alt="intellect-banner" />
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={freshey.title}
                            description={freshey.description}
                            pageUrl={freshey.pageUrl}
                            imageworkUrl={freshey.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={kitchenji.title}
                            description={kitchenji.description}
                            pageUrl={kitchenji.pageUrl}
                            imageworkUrl={kitchenji.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={shuddha.title}
                            description={shuddha.description}
                            pageUrl={shuddha.pageUrl}
                            imageworkUrl={shuddha.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Lexotique
