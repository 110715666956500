import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { transformativedigital, authorcafe, sunnybee } from '../Model/Project'


const Bharathihomes = () => {
  return (
    <>
        <Header />

        <div className="bharathi-head">
            <div className="container bharathi-head-cont">
                <h2 className='text-animate'>Bharathi Homes</h2>
                <h3 className='text-animate'>Not your average website for <br className="d-none d-lg-block" /> not your average builders.</h3>
            </div>
        </div>

        <div className="container text-animate"         >
            <img className="img-fluid bharathi-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/0e5d2a4a-bharathi-homes-main-2048x1184.png" alt="Bharathi-homes-banner" />
        </div>

        <div className="container bharathi-about">
            <div className="row">
                <div className="col-lg-6 col-md-6 bharathi-about-cont">
                    <h5 className='text-animate'>ABOUT BHARATHI HOMES</h5>
                    <p className='text-animate'>Bharathi Homes are builders on a mission to use technology to improve the lives of homeowners. Through a mixture of images, customised illustrations and bright colours, we were able to make the brand truly stand out amidst the plethora of builders found online.</p>
                </div>
                <div className="col-lg-6 col-md-6 bharathi-about-cont">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>Bharathi Homes believe they are more than just builders; they are a laboratory for living. Our goal was to increase their online presence through a website that truly resonated with their unique ideology and vision. We used customised illustrations and modern design, interesting call-outs and contemporary typography to bring out the brand story.</p>
                </div>
            </div>
        </div>

        <div className="bharathi-res">
            <div className="container bharathi-res-cont">
                <h5 className="border-bottom text-animate"         >RESEARCH</h5>
                <div className="row">
                    <div className="col-lg-5 col-md-6">
                        <p className='text-animate' >Rather of investing additional money on research, we utilised Google Analytics and Google Tag Manager to obtain quantitative data about the previous website in order to determine the users' primary pain issues.</p>
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                    <div className="col-lg-5 col-md-6 text-animate"           >
                        <img className="img-fluid" style={{padding: "0 30px 10px 30px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4910d83e-bharathi-homes-image-1.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-1 d-none d-lg-block"></div>
                </div>

                <div className="row mt-5">
                    <div className="col-lg-6 col-md-6 bharathi-ul">
                        <ul className="bharathi-li text-animate"         >
                            <li>Low Conversion RATE (CTR)</li>
                            <li>Low Persuation states</li>
                            <li>Lack of brand credibility</li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6 bharathi-ul bharathi-ul-">
                        <ul className="bharathi-li text-animate"              >
                            <li>High Bounce Rates</li>
                            <li>High Drop off Rate</li>
                            <li>Low Pageview Time</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div className="bharathi-wf">
            <div className="container bharathi-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
            </div>

            <div className="container text-animate"              >
                <img className="img-fluid bharathi-wf-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/0460b301-bharathi-homes-wireframe.png" alt="bharathi-banner" />
            </div>
        </div>

        <div className="bharathi-ill">
            <div className="container bharathi-ill-cont">
                <h5 className="border-bottom text-animate"         >ILLUSTRATIONS</h5>

                <p className='text-animate'>We used vibrant illustrations to represent what the brand delivers. <br className="d-none d-lg-block" /> To showcase care and a community where people can build a life.</p>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-4 bharathi-ill-video text-animate"         >
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/03/cbec2dfb-father-dauther.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-4 col-md-4 bharathi-ill-video text-animate"              >
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/03/0ec2da70-family.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    <div className="col-lg-4 col-md-4 bharathi-ill-video text-animate"              >
                        <video style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/03/4ae13788-residential.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                    </div>
                    

                    <div className="col-lg-12 text-animate"         >
                        <img className="img-fluid bharathi-img-ill" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7a2e32b3-bharathi-homes-image-2-2048x1382.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-12 text-animate"              >
                        <img className="img-fluid bharathi-img-ill0" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/8ebcdab4-bharathi-homes-image-3.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-12 text-animate"           >
                        <img className="img-fluid bharathi-img-ill1" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/659f095e-bharathi-homes-image-4.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-lg-12 text-animate"           >
                        <img className="img-fluid bharathi-img-ill2" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/79210336-bharathi-homes-image-5.png" alt="My-persona-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageworkUrl={authorcafe.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageworkUrl={sunnybee.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={transformativedigital.title}
                            description={transformativedigital.description}
                            pageUrl={transformativedigital.pageUrl}
                            imageworkUrl={transformativedigital.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Bharathihomes