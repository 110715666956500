import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { freshey, kitchenji, aniva } from '../Model/Project'

const Shuddha = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Shuddha Dairy​</h2>
                <h3 className='text-animate'>The promise of purity.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/916762a7-banner-2048x1024.jpg" alt="intellect-banner" />
        </div>

        <div className="container mehta-jewell-cont">
            <p>Being in the B2B segment of dairy, Shuddha Dairy wanted to move into retail and break into the competitive world of dairy in South India. With a great product Shuddha had the potential to broaden its distribution and significantly increase its sales. To help achieve this objective, Fingerprints developed a brand strategy through market and consumer analysis to underpin the design of a fresh, new logo and packaging.</p>
        </div>

        <div className="container position-relative">
            <div className="row bayleaf-ban-img">
                <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2cdf3741-logo-animation-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />

                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5f78db3a-logo-2.jpg" alt="intellect-banner" />
                </div>
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b922e0ef-cap-logo-2.jpg" alt="intellect-banner" />
                </div>

                <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/17124ad4-brand-guidelines-2-2048x1174.jpg" alt="intellect-banner" />

                <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/bb33e946-milk-packet.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />

                <div className="col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e87b5d0a-bottle.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-md-6 p-0">
                    <video className="lexotique-video" src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/61db7d62-shuddha-car-slow.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>

                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d75176a0-curd-2.jpg" alt="intellect-banner" />
                </div>
                <div className="col-md-6 p-0">
                    <img className="img-fluid vinita-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8d3ffc70-milk-packaging-mobile-2.jpg" alt="intellect-banner" />
                </div>
            </div>
        </div>


        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={freshey.title}
                            description={freshey.description}
                            pageUrl={freshey.pageUrl}
                            imageworkUrl={freshey.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={kitchenji.title}
                            description={kitchenji.description}
                            pageUrl={kitchenji.pageUrl}
                            imageworkUrl={kitchenji.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={aniva.title}
                            description={aniva.description}
                            pageUrl={aniva.pageUrl}
                            imageworkUrl={aniva.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Shuddha
