import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { ewallet, loanoriginationsystem, futbank } from '../Model/Project'

const Transformativedigitalhr = () => {
  return (
    <>

       <Header />

        <div className="container tmhr-img">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-cont-position">
                    <div className="d-block fut-bank-cont">
                        <h4  className='text-animate'>ZE Technologies</h4>
                        <h3  className='text-animate'>Human resource <br className="d-lg-none d-md-block" /> software assisting <br className="d-none d-lg-block" /> in all HR related functions.</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 tm-hr-img text-animate"              >
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/7d416cae-digital-hr-main.png" alt="digital-hr-banner" />
                </div>
            </div>
        </div>

        <div className="container fut-bank-overview">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-abov">
                    <h5 className='text-animate'>ABOUT TRANSFORMATIVE DIGITAL HR</h5>
                    <p className='text-animate'>Transformative Digital HR is an exclusive, single platform, human resources software that assists in all HR related functions. Replete with services and features that help businesses maximize the productivity of employees, Adrenalin helps automate routine HR and payroll tasks.</p>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-obj">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>To create an application for any type of office administration and management functions for HR and employees. To create a seamless application that is modular, extendable to any organization size and to automate routine tasks to give the company more time to handle critical functions.</p>
                </div>
            </div>
        </div>

        <div className="sunnybee-wf">
            <div className="container sunnybee-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAME</h5>
                <div className='hr-wf'>
                    <p className='text-animate'>Skeleton structure is a very important process in app creation. <br className="d-none d-lg-block" /> Wireframes help us clearly see the future conditional interactions that <br className="d-none d-lg-block" /> users might make and also the general workflow for each unique <br className="d-none d-lg-block" /> function to be undertaken.</p>
                </div>
                <div className="row hr-wf-img">
                    <div className="col-lg-6 col-md-6 text-animate"         >
                        <img className="img-fluid hr-wf-img0" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ae481287-asset-digital-hr.png" alt="sunny-bee-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid hr-wf-img1" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/251e6098-wireframe-digital-hr.png" alt="sunny-bee-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className='container hr-blue'>
            <p className='text-animate'>Specific customizable dashboards for<br className="d-none d-lg-block d-md-block" />employees and HR managers with specific functionality.</p>
            <img className="img-fluid text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a058acc5-digital-hr-ui_a.png" alt="sunny-bee-banner" />
        </div>

        

            <div className="container fut-bank-sec">
                <div className="row e-wallet-lg">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                        <div className="d-block fut-bank-sec-cont">
                            <h4 className='text-animate'>Smart Calendar</h4>
                            <p className='text-animate'>Set up appointments, track leave, assign tasks and <br className="d-none d-lg-block" /> track all events with the smart calendar feature with <br className="d-none d-lg-block" /> reminders.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/1ec778de-digital-hr-smart-calendar_a.png" alt="fut-bank-banner" />
                    </div>
                </div>
            </div>

            <div className="container fut-bank-ld">
                <div className="row">
                    <div className="col-lg-6 col-md-6 text-animate"              >
                        <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a011fb53-digital-hr-trackapply.png" alt="fut-bank-banner" />
                    </div>
                    <div className="col-lg-6 col-md-6 fut-bank-ld-cont">
                        <div className="d-block">
                            <h4 className='text-animate'>Track &amp; Apply for Leave</h4>
                            <p className='text-animate'>Helps employees keep track of their attendance <br className="d-none d-lg-block" /> details in a single click by tracking their casual and <br className="d-none d-lg-block" /> sick leave.</p>
                        </div>
                    </div>
                </div>
            </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={ewallet.title}
                            description={ewallet.description}
                            pageUrl={ewallet.pageUrl}
                            imageworkUrl={ewallet.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={loanoriginationsystem.title}
                            description={loanoriginationsystem.description}
                            pageUrl={loanoriginationsystem.pageUrl}
                            imageworkUrl={loanoriginationsystem.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageworkUrl={futbank.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Transformativedigitalhr
