import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { sequencelounge, airportzo, futbank } from '../Model/Project'


const Kokumstore = () => {
  return (
    <>

      <Header />

        <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>Kokum Store</h2>
                <h3 className='text-animate'>Designing an ecommerce site for <br className="d-none d-lg-block" />contemporary designer wear.</h3>
            </div>
        </div>

        
        <div className="ks-ban-bg">
            <div className="container private-earth-ban text-animate"              >
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/41b5bd5f-tks1-2048x1202.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="seq-lounge-obj">
            <div className="container seq-lounge-obj-cont">
                <h5 className="border-bottom text-animate"         >OBJECTIVE</h5>
                <p className='text-animate'>Kokum Store curates hand woven fabrics sourced from traditional craftsmen to create contemporary clothing for the modern woman. With minimalism, clean lines and elegance in its DNA, we created an e-commerce site for the brand that resonated with these values. Tasked with increasing traffic and sales, our approach was to create a clean, contemporary look where several collections could easily be viewed and the brand’s fresh perspective on fashion was at the forefront. The user-friendly design made shopping a breeze, thus generating great sales for the client.</p>
            </div>
        </div>

     

        <div className="seq-lounge-wf">
            <div className="container seq-lounge-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
            </div>

            <div className="container text-animate"              >
                <img className="img-fluid" style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/e2267d29-ks2-1.png" alt="seq-lounge-banner" />
            </div>

            <div className="container">
                <img className="img-fluid d-none d-lg-block d-md-block text-animate"               style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/b2d2b03c-tks2-2048x1298.png" alt="seq-lounge-banner" />

                <div className="row d-md-none d-lg-none d-xl-none">
                    <div className="col-md-12 text-center text-animate"         >
                        <img className="img-fluid vinveli-wf-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/f553d7d4-tks3.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-md-12 text-animate"              >
                        <img className="img-fluid vinveli-wf-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/3115dd1d-tks4.png" alt="My-persona-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="ks-img-bgb">
            <div className="container text-animate"              >
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/7c2c9477-tks5.png" alt="vinveli-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sequencelounge.title}
                            description={sequencelounge.description}
                            pageUrl={sequencelounge.pageUrl}
                            imageworkUrl={sequencelounge.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={airportzo.title}
                            description={airportzo.description}
                            pageUrl={airportzo.pageUrl}
                            imageworkUrl={airportzo.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageworkUrl={futbank.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>
      <Footer />
    </>
  )
}

export default Kokumstore
