import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { waycool, loanoriginationsystem, futbank } from '../Model/Project'

const Intellect = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Intellect : Annual Report</h2>
                <h3 className='text-animate'>The world's no.2 in digital banking,<br className="d-none d-lg-block" /> banks on us.</h3>
            </div>
        </div>

        <div className="container text-animate intellect-bann-img">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/46c221fb-intellect-cover-1.png" alt="intellect-banner" />
        </div>

        <div className="intellect-bg">
            <div className="container">
                <div className="row intellect-over">
                    <div className="col-lg-6 col-md-6 intellect-over-cont">
                        <p>Intellect Design Arena, a global leader in financial technology for banking, insurance and financial services invests in our design and communication services for their annual report</p>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/43c603dc-asset-5.png" alt="intellect" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="text-animate">
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c6ec6481-15-2048x1536.jpg" alt="intellect" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/ae6ef818-7-2048x1536.jpg" alt="intellect" />
            </div>

            <div className="intellect-para">
                <p>Fingerprints collaborated with key stakeholders across Intellects’ business vertices to knowledge transfer and grasp the key business milestones that we could use to communicate their brand purpose and explain future strategy through engaging layouts, visual storytelling and informative infographics.</p>
            </div>

            <div className="row intellect-img">
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/fc645db4-2.jpg" alt="intellect" />
                </div>
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/73dfb781-3.jpg" alt="intellect" />
                </div>
            </div>

            <div className="text-animate">
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b05eab4a-11-2048x1536.jpg" alt="intellect" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/337bd506-12-2048x1536.jpg" alt="intellect" />
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5ad84698-5.jpg" alt="intellect" />
                </div>
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b3f1dc21-4.jpg" alt="intellect" />
                </div>
            </div>

            <div className="intellect-para">
                <p>The report uses custom illustrated imagery, treated with bold treatments and infographics. It presents an engaging annual report without losing focus on its core communication.</p>
            </div>
        </div>

        <div className="intellect-patt-bg"></div>

        <div className="container text-animate">
            <img className="img-fluid" style={{padding: "0 10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/3e20995e-8-2048x1536.jpg" alt="intellect-banner" />
        </div>

        <div className="container text-animate">
            <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/1939b0c1-9-1-2048x1536.jpg" alt="intellect-banner" />
        </div>

        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5ea788c4-10-2048x1536.jpg" alt="intellect" />
                </div>
                <div className="col-lg-6 col-md-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/943e4e3d-14-2048x1536.jpg" alt="intellect" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/482fe1b5-17-2048x1536.jpg" alt="intellect" />
                </div>
                <div className="col-lg-6">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a18f3657-13-2048x1536.jpg" alt="intellect" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={waycool.title}
                            description={waycool.description}
                            pageUrl={waycool.pageUrl}
                            imageworkUrl={waycool.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={loanoriginationsystem.title}
                            description={loanoriginationsystem.description}
                            pageUrl={loanoriginationsystem.pageUrl}
                            imageworkUrl={loanoriginationsystem.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageworkUrl={futbank.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Intellect