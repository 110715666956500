import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Seeourthinking from "../Components/Seeourthinking";
import { ewallet, flyet, futbank } from "../Model/Project";

const SunnybeeLatest = () => {
  return (
    <>
      <Header />

      <div className="my-perso-head">
        <div className="container my-perso-head">
          <h2 className="text-animate">SunnyBee Market</h2>
          <h3 className="text-animate">
            Taking a physical retail store
            <br className="d-none d-lg-block" /> to the Future and meeting its
            <br className="d-none d-lg-block" /> customers there.
          </h3>
        </div>
      </div>

      <div className="container text-animate intellect-bann-img">
        <video
          loop
          autoPlay
          muted
          className="img-fluid"
          src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-1-comp.mp4"
          alt="sunnybee-banner"></video>
      </div>

      <div className="container vinveli-over">
        <div className="row">
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4 col-md-6 vinv-over">
            <h2 className="text-animate">
              What went into the
              <br className="d-none d-lg-block d-md-block" /> creation of the
              app?{" "}
            </h2>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-6 col-md-6 vinv-over">
            <p className="text-animate">
              During the pandemic, when people were unwilling to step out of
              their homes, we brought the store to them through a simple, secure
              and easily navigable mobile app.
            </p>
            <p className="text-animate">
              With a light weight design and AI enabled search, users could
              browse through thousands of products, pay securely and quickly
              with our cutting edge wallet system and have their purchases
              shipped straight to their door.
            </p>
          </div>

          <div className="col-lg-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-7-comp.mp4"
              alt="sunnybee"></video>
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://ik.imagekit.io/future/assets/tr:f-auto/sunnybee-img-3.png"
              alt="sunnybee"
            />
          </div>
        </div>

        <div className="container">
          <div className="row e-wallet-lg flyet-card-reverse pt-5 py-md-5">
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/sunnybee-img-5.png"
                alt="sunnybee-banner"
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">AI Powered Search</h5>
                <p className="text-animate">
                  Search was one of the core functionalities we built into the
                  app. In fact, we built AI into the search which helped users
                  find exactly what they were looking for.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="sunnybee-bg-color my-5 text-center pt-5">
          <h5 className="text-animate text-white text-center ">
            Quick & Easy Payments
          </h5>
          <p className="text-animate text-white text-center pb-5">
            The wallet was built within the app to make the payments{" "}
            <br className="d-none d-lg-block d-md-block" /> quicker and easier.
            Users could load their wallet with money{" "}
            <br className="d-none d-lg-block d-md-block" />
            and checkout instantly with just a tap.
          </p>
          <div className="text-animate container">
            <img
              className="img-fluid w-75"
              src="https://ik.imagekit.io/future/assets/tr:f-auto/sunnybee-qe-payment.png"
              alt="sunnybee"
            />
          </div>
        </div>

        <div className="container text-animate">
          <video
            loop
            autoPlay
            muted
            className="img-fluid"
            // style={{ padding: "0 10px" }}
            src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-order-place-comp.mp4"
            alt="sunnybee-banner"></video>
        </div>

        <div className="container pt-5 pb-md-5">
          <div className="row e-wallet-lg">
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Reachability</h5>
                <p className="text-animate">
                  Primary actions like search, cart and checkout were designed
                  keeping the size of phone screens in mind. To make the user
                  experience seamless, all important actions were placed at the
                  very bottom of the screen for one handed operation.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/sunnybee-img-8.png"
                alt="fut-bank-banner"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container text-animate py-4">
        <video
          loop
          autoPlay
          muted
          className="img-fluid"
          // style={{ padding: "0 10px" }}
          src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-add-cart-comp.mp4"
          alt="sunnybee-banner"></video>
      </div>

      <div className="container text-animate py-4">
        <video
          loop
          autoPlay
          muted
          className="img-fluid"
          // style={{ padding: "0 10px" }}
          src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-4-ff-comp.mp4"
          alt="sunnybee-banner"></video>
      </div>

      <div className="container">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-5-1-comp.mp4"
              alt="sunnybee"></video>
          </div>
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/sunnybee-place-comp.mp4"
              alt="sunnybee"></video>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div>
        <div className="container sotia text-animate">
          <h2>See our thinking in action.</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={ewallet.title}
                description={ewallet.description}
                pageUrl={ewallet.pageUrl}
                imageworkUrl={ewallet.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={flyet.title}
                description={flyet.description}
                pageUrl={flyet.pageUrl}
                imageworkUrl={flyet.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={futbank.title}
                description={futbank.description}
                pageUrl={futbank.pageUrl}
                imageworkUrl={futbank.imageworkUrl}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SunnybeeLatest;
