import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { vinveliweb, sequencelounge, privateearth } from '../Model/Project'


const Thexanadu = () => {
  return (
    <>

      <Header />
        <div className="seq-lounge-head">
            <div className="container seq-lounge-head">
                <h2 className='text-animate'>The Xanadu</h2>
                <h3 className='text-animate'>Redefining vacations, one click at a time.</h3>
            </div>
        </div>

        <div className="xanadu-bgcl">
            <div className="container seq-lounge-img text-animate"              >
                <img className="img-fluid seq-lounge-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/3eb49277-51651651620.png" alt="xanadu-banner" />
            </div>
        </div>

        <div className="seq-lounge-obj">
                <div className="container seq-lounge-obj-cont">
                    <h5 className="border-bottom text-animate"         >OBJECTIVE</h5>
                    <p className='text-animate'>The Xanadu is a chain of premium, fully-serviced luxury vacation rental villas, where every room and home is unique. Our objective was to increase their online bookings by creating a strong brand presence online. We did this by designing a website that showcased their strengths – beautiful, charming properties, set in the midst of nature. With all their properties, services, amenities and booking information upfront, the site also featured interesting information like places of interest nearby, things to do, etc. The clean layout and stunning visuals enhanced the user experience and brought great traffic to the site.</p>
                </div>
        </div>

        <div className="seq-lounge-wf">
                <div className="container seq-lounge-wf-cont">
                    <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
                </div>

                <div className="container text-animate"  style={{textAlign: 'center'}}              >
                    <img className="img-fluid" style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/9d005dca-x2.png" alt="xanadu-banner" />
                </div>
        </div>

        <div className="seq-lounge-wf">
                <div className="container seq-lounge-wf-cont">
                    <h5 className="border-bottom text-animate"         >ICONOGRAPHY</h5>
                </div>

                <div className="container text-animate"  style={{textAlign: 'center'}}              >
                    <img className="img-fluid " style={{paddingTop: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/71242c68-x3.png" alt="xanadu-banner" />
                </div>
                <div className="container text-animate"  style={{textAlign: 'center'}}         >
                    <img className="img-fluid xan-img-top-pad" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/a3d93de6-x4.png" alt="xanadu-banner" />
                </div>
        </div>

        <div className="xan-blue">
            <div className="container text-animate"  style={{textAlign: 'center'}}              >
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/f5f43ca6-x5.png" alt="xanadu-banner" />
            </div>
        </div>

        <div className="container xan-last text-animate"  style={{textAlign: 'center'}}         >
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/52c1b28a-x6.png" alt="xanadu-banner" />
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sequencelounge.title}
                            description={sequencelounge.description}
                            pageUrl={sequencelounge.pageUrl}
                            imageworkUrl={sequencelounge.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={privateearth.title}
                            description={privateearth.description}
                            pageUrl={privateearth.pageUrl}
                            imageworkUrl={privateearth.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinveliweb.title}
                            description={vinveliweb.description}
                            pageUrl={vinveliweb.pageUrl}
                            imageworkUrl={vinveliweb.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

      <Footer />
    </>
  )
}

export default Thexanadu