import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { vapho, writerscafe, desidi } from '../Model/Project'

const Hilton = () => {
  return (
    < >
      <Header />
      <div className="vinveli-head">
            <div className="container vinveli-head-cont">
                <h2 className='text-animate'>Hilton</h2>
                <h3 className='text-animate'>Creating memorable <br className="d-none d-lg-block" /> communication over the years.</h3>
            </div>
    </div>

    <div className="container text-animate">
        <img className="img-fluid vinveli-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e43b7624-hilton-cover-2048x1024.jpg" alt="vinveli-banner" />
    </div>

    <div className="container fut-bank-des text-center">
        <p className='text-animate'>We've done brand campaigns for The Hilton Chennai that captures the essence of this <br className="d-none d-lg-block d-md-block" /> sophisticated yet quirky brand. From food festivals to Christmas campaigns, from menus to posters and print ads, <br className="d-none d-lg-block d-md-block" /> we've maintained the brand's distinct identity while creating eye-catching and edgy creatives.</p>
        <img className="img-fluid " style={{padding: "0px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f83a55a1-image-1.png" alt="vapho-banner" />
    </div>

    <div className="container">

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/dc4f5b63-image1-1.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/3386e131-image2-1.png" alt='vapho'/>
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/298491b2-v5-1.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/da508fc5-v6-1.png" alt='vapho'/>
                </div>
            </div>

            <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/b69eb89f-image3-1.png" alt='vapho'/>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/34d9203f-image4-1.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/17a4b8e6-image5-1.png" alt='vapho'/>
                </div>
            </div>

            <img className="img-fluid " style={{paddingTop: "20px", paddingBottom: "40px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/49a64043-image6-1.png" alt='vapho'/>


        </div>

    <div className="container">
            <div className="border-bb"></div>
        </div>
    <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageworkUrl={writerscafe.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={desidi.title}
                            description={desidi.description}
                            pageUrl={desidi.pageUrl}
                            imageworkUrl={desidi.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vapho.title}
                            description={vapho.description}
                            pageUrl={vapho.pageUrl}
                            imageworkUrl={vapho.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />

    </>
  )
}

export default Hilton
