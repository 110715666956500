import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Seeourthinking from "../Components/Seeourthinking";
import { ewalletLatest, sunnybeeLatest, futbankLatest } from "../Model/Project";

const Flyet = () => {
  return (
    <>
      <Header />

      <div className="my-perso-head">
        <div className="container my-perso-head">
          <h2 className="text-animate">Flyet</h2>
          <h3 className="text-animate">
            Creating a Seamless Travel
            <br className="d-none d-lg-block" /> Booking Experience.
          </h3>
        </div>
      </div>

      <div className="container text-animate intellect-bann-img">
        <img
          className="img-fluid d-none d-md-block"
          src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-1.png"
          alt="intellect-banner"
        />
        <img
          className="img-fluid d-md-none"
          src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-resp1.png"
          alt="intellect-banner"
        />
      </div>

      <div className="container vinveli-over">
        <div className="row">
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4 col-md-6 vinv-over">
            <h2 className="text-animate">
              What went into the
              <br className="d-none d-lg-block d-md-block" /> creation of the
              website?{" "}
            </h2>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-6 col-md-6 vinv-over">
            <p className="text-animate">
              To create a travel booking web application that truly catered to
              the needs of users, our team conducted extensive research and
              analysis of user needs and pain points. We carefully considered
              factors such as the booking process, navigation, user preferences,
              and common issues encountered by travelers.
            </p>
            <p className="text-animate">
              One of our key priorities was to create an all-in-one solution
              that would allow users to easily book flights, hotels, and car
              rentals on a single platform. This streamlines the booking
              process, making it more convenient and efficient for users.We also
              prioritized the design of an intuitive and user-friendly
              interface, which would enable users to easily navigate and
              interact with the application.
            </p>
          </div>

          <div className="col-lg-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/flyet-video-1.mp4"
              alt="intellect"></video>
          </div>
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/flyet-video-2.mp4"
              alt="intellect"
            />
          </div>
        </div>

        <div className="container pt-5 pb-md-5">
          <div className="row e-wallet-lg">
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Simplified Flight Selection</h5>
                <p className="text-animate">
                  Selecting a flight from the flights result page is a critical
                  step in the travel booking process, which is why we
                  prioritized a seamless experience for this feature. By
                  designing an intuitive and clean interface that allows users
                  to easily filter and sort flight options based on their
                  preferences.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-4.png"
                alt="fut-bank-banner"
              />
            </div>
          </div>
        </div>

        <div className="text-animate py-5 ">
          <img
            className="img-fluid"
            style={{ padding: "10px" }}
            src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-5.png"
            alt="intellect"
          />
        </div>

        <div className="container">
          <div className="row e-wallet-lg flyet-card-reverse py-md-5">
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-6.png"
                alt="fut-bank-banner"
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Smarter Travel Planning</h5>
                <p className="text-animate">
                  Our travel booking app provides personalized travel
                  recommendations to users based on their preferences and past
                  bookings. By utilizing data analysis and machine learning
                  algorithms, we are able to deliver customised travel
                  suggestions that are tailored to the user's unique travel
                  history, interests, and preferences.
                </p>
                <p className="text-animate">
                  This feature not only helps users discover new and exciting
                  destinations that they may have otherwise overlooked, but it
                  also streamlines the booking process by providing relevant and
                  targeted recommendations.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flyet-bg-color my-5">
          <div className="text-animate container">
            <img
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-7.png"
              alt="intellect"
            />
          </div>
        </div>

        <div className="container vinveli-over">
          <div className="row mb-md-5">
            {/* <div className="col-lg-1 d-none d-lg-block"></div> */}
            <div className="col-lg-4 col-md-6 vinv-over">
              <h2 className="text-animate">
                A Scalable
                <br className="d-none d-lg-block d-md-block" /> Design System
              </h2>
            </div>
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-lg-6 col-md-6 vinv-over">
              <p className="text-animate">
                At the heart of our travel booking app is a robust design system
                that ensures a consistent user experience across all devices. We
                understand that users may access the app from various devices,
                which is why we focused on building a system that is both
                flexible and scalable.
              </p>
              <p className="text-animate">
                To achieve this, we created a suite of custom UI components that
                are designed to be modular and reusable, providing a consistent
                look and feel across the entire app. This ensures that users can
                easily navigate the app, regardless of the device they are
                using.
              </p>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>

      <div className="container text-animate">
        <img
          className="img-fluid"
          style={{ padding: "0 10px" }}
          src="https://ik.imagekit.io/future/assets/tr:f-auto/tb-img-8-1.png"
          alt="intellect-banner"
        />
      </div>

      <div className="container vinveli-over">
        <div className="row">
          {/* <div className="col-lg-1 d-none d-lg-block"></div> */}
          <div className="col-lg-4 col-md-6 vinv-over">
            <h2 className="text-animate">
              Seamless Across
              <br className="d-none d-lg-block d-md-block" /> All Devices
            </h2>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-6 col-md-6 vinv-over">
            <p className="text-animate">
              Our travel booking web application is designed to work seamlessly
              across all devices, including desktops, laptops, tablets, and
              smartphones. This is achieved through a responsive design approach
              that ensures the app's layout and functionality adapt to fit the
              user's device.
            </p>
            <p className="text-animate">
              Regardless of the device a user is accessing the app from, they
              can expect a consistent and intuitive experience. The app's user
              interface is designed to be simple and easy to use, with all
              features and functions accessible from a single platform.
            </p>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div>
        <div className="container sotia text-animate">
          <h2>See our thinking in action.</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={futbankLatest.title}
                description={futbankLatest.description}
                pageUrl={futbankLatest.pageUrl}
                imageworkUrl={futbankLatest.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={sunnybeeLatest.title}
                description={sunnybeeLatest.description}
                pageUrl={sunnybeeLatest.pageUrl}
                imageworkUrl={sunnybeeLatest.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={ewalletLatest.title}
                description={ewalletLatest.description}
                pageUrl={ewalletLatest.pageUrl}
                imageworkUrl={ewalletLatest.imageworkUrl}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Flyet;
