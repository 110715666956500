import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./Styles/App.css";
import "./Styles/Responsive.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Work from "./Pages/Work";
import Services from "./Pages/Services";
import Contact from "./Pages/Contact";
import Mypersona from "./Works/Mypersona";
import Airportzo from "./Works/Airportzo";
import Authorcafe from "./Works/Authorcafe";
import Bharathihomes from "./Works/Bharathihomes";
import Ewallet from "./Works/Ewallet";
import Futbank from "./Works/Futbank";
import Sunnybee from "./Works/Sunnybee";
import GbrTMT from "./Works/Gbrtmt";
import Kokumstore from "./Works/Kokumstore";
import Loanoriginationsystem from "./Works/Loanoriginationsystem";
import Privateearth from "./Works/Privateearth";
import Sequencelounge from "./Works/Sequencelounge";
import Thexanadu from "./Works/Thexanadu";
import VinveliWeb from "./Works/Vinveliweb";
import Transformativedigitalhr from "./Works/Transformativedigitalhr";
import Vapho from "./Works/Vapho";
import Aniva from "./Works/Aniva";
import Sunnybeebread from "./Works/Sunnybeebread";
import Vinveli from "./Works/Vinveli";
import Freshey from "./Works/Freshey";
import Intellect from "./Works/Intellect";
import Bayleaf from "./Works/Bayleaf";
import Writerscafe from "./Works/Writerscafe";
import Asianpaints from "./Works/Asianpaints";
import Lexotique from "./Works/Lexotique";
import Mehtajewellery from "./Works/Mehtajewellery";
import Sunnybeebranding from "./Works/Sunnybeebranding";
import Hilton from "./Works/Hilton";
import Sunnybeehoney from "./Works/Sunnybeehoney";
import Curiohh from "./Works/Curiohh";
import Waycool from "./Works/Waycool";
import Abbys from "./Works/Abbys";
import Oswinply from "./Works/Oswinply";
import Vinitahospital from "./Works/Vinitahospital";
import Careers from "./Pages/Careers";
import Desidi from "./Works/Desidi";
import Kitchenji from "./Works/Kitchenji";
import Shuddha from "./Works/Shuddha";
import Curiohhtravel from "./Works/Curiohhtravel";
import Amelies from "./Works/Amelies";
import Uiux from "./Opportunities/Uiux";
// import Reactnative from "./Opportunities/Reactnative";
// import DMSpecialist from "./Opportunities/DMSpecialist";
import WebPM from "./Opportunities/WebPM";
// import Hrmanager from "./Opportunities/Hrmanager";
import Thankyou from "./Pages/Thankyou";
import Mahasaghar from "./Works/mahasaghar";
import Flyet from "./Works/flyet";
import SunnybeeLatest from "./Works/SunnybeeLatest";
import FutbankLatest from "./Works/FutbankLatest";
import EwalletLatest from "./Works/EwalletLatest";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter basename="/">
    <Routes>
      {/* Pages */}
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/work" element={<Work />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/careers" element={<Careers />} />
      <Route exact path="/thankyou" element={<Thankyou />} />

      {/* Opportunities */}
      <Route exact path="/opportunities/ui-ux-designer" element={<Uiux />} />
      <Route
        exact
        path="/opportunities/web-product-manager"
        element={<WebPM />}
      />
      {/* <Route exact path='/opportunities/react-native-developer' element={<Reactnative />} /> */}
      {/* <Route exact path='/opportunities/digital-marketing-specialist' element={<DMSpecialist />} /> */}
      {/* <Route exact path='/opportunities/hr-manager' element={<Hrmanager />} /> */}

      {/* Works */}
      <Route exact path="/work/mypersona" element={<Mypersona />} />
      <Route exact path="/work/airportzo" element={<Airportzo />} />
      <Route
        exact
        path="/work/authorcafe-website-development"
        element={<Authorcafe />}
      />
      <Route
        exact
        path="/work/bharathi-homes-website-design"
        element={<Bharathihomes />}
      />
      <Route
        exact
        path="/work/e-wallet-app-design"
        element={<EwalletLatest />}
      />
      <Route exact path="/work/fut-bank-app" element={<FutbankLatest />} />
      <Route exact path="/work/gbr-tmt" element={<GbrTMT />} />
      <Route exact path="/work/kokum-store" element={<Kokumstore />} />
      <Route
        exact
        path="/work/loan-origination-system"
        element={<Loanoriginationsystem />}
      />
      <Route exact path="/work/private-earth" element={<Privateearth />} />
      <Route exact path="/work/sequence-lounge" element={<Sequencelounge />} />
      <Route
        exact
        path="/work/sunnybee-mobile-app"
        element={<SunnybeeLatest />}
      />
      <Route exact path="/work/the-xanadu" element={<Thexanadu />} />
      <Route
        exact
        path="/work/transformative-digital-hr"
        element={<Transformativedigitalhr />}
      />
      <Route exact path="/work/vinveli-website" element={<VinveliWeb />} />

      {/* New Works */}
      <Route exact path="/work/vapho" element={<Vapho />} />
      <Route exact path="/work/aniva-identity" element={<Aniva />} />
      <Route
        exact
        path="/work/sunny-bee-bread-packaging"
        element={<Sunnybeebread />}
      />
      <Route exact path="/work/vinveli" element={<Vinveli />} />
      <Route exact path="/work/freshey" element={<Freshey />} />
      <Route exact path="/work/intellect" element={<Intellect />} />
      <Route exact path="/work/bayleaf" element={<Bayleaf />} />
      <Route exact path="/work/writers-cafe" element={<Writerscafe />} />
      <Route exact path="/work/asian-paints" element={<Asianpaints />} />
      <Route exact path="/work/lexotique" element={<Lexotique />} />
      <Route
        exact
        path="/work/mehta-jewellery-social-media"
        element={<Mehtajewellery />}
      />
      <Route
        exact
        path="/work/sunny-bee-identity"
        element={<Sunnybeebranding />}
      />
      <Route exact path="/work/hilton" element={<Hilton />} />
      <Route
        exact
        path="/work/sunny-bee-honey-packaging"
        element={<Sunnybeehoney />}
      />
      <Route exact path="/work/curiohh" element={<Curiohh />} />
      <Route exact path="/work/waycool" element={<Waycool />} />
      <Route exact path="/work/abbys" element={<Abbys />} />
      <Route exact path="/work/oswin-ply" element={<Oswinply />} />
      <Route exact path="/work/vinita-hospital" element={<Vinitahospital />} />
      <Route exact path="/work/desi-di" element={<Desidi />} />
      <Route exact path="/work/kitchen-ji" element={<Kitchenji />} />
      <Route exact path="/work/shuddha" element={<Shuddha />} />
      <Route
        exact
        path="/work/curiohh-travel-merchandise-branding"
        element={<Curiohhtravel />}
      />
      <Route exact path="/work/amelies" element={<Amelies />} />
      <Route exact path="/work/mahasaghar" element={<Mahasaghar />} />
      <Route exact path="/work/flyet" element={<Flyet />} />
      {/* <Route exact path="/work/sunnybee-latest" element={<SunnybeeLatest />} />
      <Route exact path="/work/futbank-latest" element={<FutbankLatest />} />
      <Route exact path="/work/ewallet-latest" element={<EwalletLatest />} /> */}
    </Routes>
  </BrowserRouter>
);
