import Header from "../Components/Header";
import React, { Component, useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import * as axios from "axios";
import swal from "sweetalert";
import Api from "../../package.json";
import { useNavigate } from "react-router-dom";
const qs = require("qs");

const Contact = () => {
  const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem("access_token"),
  };
  const [captcha, setHeadercaptcha] = useState([]);
  const [SubmitButton, setSubmitButton] = useState("SEND MESSAGE");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    buttonRef.current.disabled = true;
    setSubmitButton("SUBMITTING...");
    save_enquiry(data);
  };
  const save_enquiry = async (data) => {
    if (
      data.brand_identity_brand_setup == false &&
      data.social_strategy_management == false &&
      data.packaging == false &&
      data.illustrations == false &&
      data.marketing_collaterals == false &&
      data.brand_identity_brand_setup == false &&
      data.others == false
    ) {
      swal("Error !", "Please select alteast one type of requirement", "error");
      buttonRef.current.disabled = false;
      setSubmitButton("Submit");
    } else {
      const response = await axios.post(
        Api.userdata.url + "/site/landing/save_enquiry_fpscreative",
        qs.stringify({
          fullname: data.fullname,
          email: data.email,
          mobilenumber: data.mobilenumber,
          message: data.message,
          brand_identity_brand_setup: data.brand_identity_brand_setup,
          social_strategy_management: data.social_strategy_management,
          content_creation_for_social_media:
            data.content_creation_for_social_media,
          packaging: data.packaging,
          illustrations: data.illustrations,
          marketing_collaterals: data.marketing_collaterals,
          others: data.others,
          // enquiry_captcha: data.enquiry_captcha,
        })
      );
      const json = await response.data;
      if (json["status"] === "OK") {
        navigate("/thankyou");
        /*swal("Successful Submitted", json["message"], "success").then((value) => {
            window.location.reload(false);
        });*/
      } else {
        swal("Error !", json["message"], "error");
      }
      buttonRef.current.disabled = false;
      setSubmitButton("Submit");
    }
  };
  useEffect(() => {
    // getLoadData();
  }, []);
  return (
    <>
      <Header />

      <div className="container foot-form">
        <div className="row">
          <div className="col-lg-7 foot-form-cont">
            <h2>
              Let us collaborate to{" "}
              <br className="d-none d-lg-block d-md-block" /> create experiences
            </h2>
            <div className="">
              <form
                className="form-group"
                method="POST"
                onSubmit={handleSubmit(onSubmit)}>
                <input
                  className="form-control"
                  type="text"
                  name="fullname"
                  {...register("fullname", {
                    required: {
                      value: true,
                      message: "Please enter your name",
                    },
                  })}
                  placeholder="Name"
                />
                {errors.fullname && (
                  <span class="errors">{errors.fullname.message}</span>
                )}
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  placeholder="Email"
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Please enter your email",
                    },
                  })}
                />
                {errors.email && (
                  <span class="errors">{errors.email.message}</span>
                )}
                <input
                  className="form-control"
                  type="number"
                  name="mobilenumber"
                  placeholder="Phone number"
                  {...register("mobilenumber", {
                    required: {
                      value: true,
                      message: "Please enter your mobile number",
                    },
                  })}
                />
                {errors.mobilenumber && (
                  <span class="errors">{errors.mobilenumber.message}</span>
                )}
                <div className="form-control-input-g">
                  <h5>Type of requirement</h5>
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check1"
                    name="brand_identity_brand_setup"
                    value="Brand Identity/Brand setup"
                    {...register("brand_identity_brand_setup")}
                  />
                  <label class="form-check-label" for="check1">
                    Brand Identity/Brand setup
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check2"
                    name="social_strategy_management"
                    value="Social strategy/Management"
                    {...register("social_strategy_management")}
                  />
                  <label class="form-check-label" for="check2">
                    Social strategy/Management
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check3"
                    name="content_creation_for_social_media"
                    value="Content creation for social media"
                    {...register("content_creation_for_social_media")}
                  />
                  <label class="form-check-label" for="check3">
                    Content creation for social media
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check4"
                    name="packaging"
                    value="Packaging"
                    {...register("packaging")}
                  />
                  <label class="form-check-label" for="check4">
                    Packaging
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check5"
                    name="illustrations"
                    value="Illustrations"
                    {...register("illustrations")}
                  />
                  <label class="form-check-label" for="check5">
                    Illustrations
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check6"
                    name="marketing_collaterals"
                    value="Marketing Collaterals"
                    {...register("marketing_collaterals")}
                  />
                  <label class="form-check-label" for="check6">
                    Marketing Collaterals
                  </label>
                  <br />
                  <input
                    type="checkbox"
                    class="form-control-input"
                    id="check7"
                    name="others"
                    value="Others"
                    {...register("others")}
                  />
                  <label class="form-check-label" for="check7">
                    Others
                  </label>
                  <br />
                </div>
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Tell us about your project"
                  {...register("message", {
                    required: {
                      value: true,
                      message: "Please tell us about your project",
                    },
                    minLength: {
                      value: 6,
                      message: "please provide atleast 10 char",
                    },
                  })}
                />
                {errors.message && (
                  <span class="errors">{errors.message.message}</span>
                )}
                <button
                  id="main-submit"
                  className="form-control"
                  ref={buttonRef}>
                  {" "}
                  {SubmitButton}
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-5 foot-address">
            <div className="foot-addr">
              <h2>Chennai (Head Office)</h2>
              <h3>
                15/4, 3rd Floor, Haddows Lane, Haddows Road, Nungambakkam,
                Chennai - 600006
              </h3>
            </div>
            <div className="foot-addr">
              <h3>For Business Enquiries</h3>
              <h2>
                <a href="mailto:aishwarya@fingerprintscreative.com">
                  aishwarya@fingerprintscreative.com
                </a>
                <br /> <a href="tel:+919176111675">+91 91761 11675</a>
              </h2>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div className="container">
        <div className="row foot-li">
          <div className="col-lg-9 col-md-9">
            <h2>
              <a href="/about">About</a>
            </h2>
            <h2>
              <a href="/work">Work</a>
            </h2>
            <h2>
              <a href="/services">Services</a>
            </h2>
            <h2>
              <a href="/contact">Contact</a>
            </h2>
          </div>
          <div className="col-lg-3 col-md-3">
            <h2>
              <a
                href="https://www.instagram.com/fingerprints_creative/"
                target="blank">
                Instagram
              </a>
            </h2>
            <h2>
              <a
                href="https://www.facebook.com/Fingerprintscreative/"
                target="blank">
                Facebook
              </a>
            </h2>
            <h2>
              <a
                href="https://www.youtube.com/user/fingerprintscreative"
                target="blank">
                Youtube
              </a>
            </h2>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div className="container">
        <div className="row footer-addr">
          <div className="col-lg-4 col-md-4">
            <h2>Chennai (Head Office)</h2>
            <h3>
              15/4, 3rd Floor, Haddows Lane, <br />
              Haddows Road, Nungambakkam, <br />
              Chennai - 600006
            </h3>
          </div>
          <div className="col-lg-4 col-md-4">
            <h3>For Business Enquiries</h3>
            <h2>
              <a href="mailto:aishwarya@fingerprintscreative.com">
                aishwarya@fingerprintscreative.com
              </a>
              <br /> <a href="tel:+919176111675">+91 91761 11675</a>
            </h2>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div className="container">
        <div className="row footer-copy">
          <div className="col-lg-4 col-md-6 foot-copy">
            <h2>2024 Fingerprints Creative Private Limited.</h2>
          </div>
          <div className="col-lg-6 col-md-2"></div>
          <div className="col-lg-2 col-md-4 d-flex justify-content-end">
            <div className="foot-img d-none d-lg-block d-md-block">
              <img
                src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/f5377b44-tiger.svg"
                alt="Finger-prints"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
