import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { writerscafe, abbys, hilton } from '../Model/Project'

const Vapho = () => {
  return (
    <>
      <Header />

        <div className="vinveli-head">
              <div className="container vinveli-head-cont">
                  <h2 className='text-animate'>Vapho</h2>
                  <h3 className='text-animate'>Smart, quirky communication for <br className="d-none d-lg-block" /> a food brand with something different to say.</h3>
              </div>
        </div>

        <div className="container text-animate">
              <img className="img-fluid vinveli-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/24eb00b6-v1-1.png" alt="vapho-banner" />
        </div>

        <div className="container fut-bank-des text-center">
            <p className='text-animate'>Va Pho was our baby in every sense of the word. From creating the distinctive <br className="d-none d-lg-block d-md-block" /> name and character that went behind the pan Asian restaurant with a <br className="d-none d-lg-block d-md-block" /> difference to the brand story, the interiors and restaurant collaterals like <br className="d-none d-lg-block d-md-block" /> menus, posters, etc., we also gave the brand a unique identity on social <br className="d-none d-lg-block d-md-block" /> media and mainline communication that was as clutter-breaking and <br className="d-none d-lg-block d-md-block" /> refreshing as the food itself.</p>

            <video className="vapho-video1" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a5dea377-section-01.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />

            <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/aa80af81-v2-1-768x441.png" alt="vapho-banner" />
        </div>

        <div className="container seq-lounge-mob">
            <div  className="row">
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"         >
                    <video style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/79e65454-kublai-khan.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"              >
                    <video style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/3f1c2d89-monkey-call.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"           >
                    <video style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/8e229740-vapho-logo.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"         >
                    <video style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/ca42fe12-boat-tub.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"              >
                    <video style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/e6ed9504-now-open.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-4 col-md-4 d-flex justify-content-center text-animate"           >
                    <video style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/bae45fc1-alien-abduction.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f70f0286-v3-1.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/adcc7466-v4-1.png" alt='vapho'/>
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/298491b2-v5-1.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/da508fc5-v6-1.png" alt='vapho'/>
                </div>
            </div>

            <div  className="row">
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"         >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/5dea8c23-v7-1.png" alt='vapho'/>
                </div>
                <div className="col-lg-6 col-md-6 d-flex justify-content-center text-animate"              >
                    <img className="img-fluid " style={{paddingTop: "20px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/d0be6599-v8-1.png" alt='vapho'/>
                </div>
            </div>

            <video className='text-animate' style={{paddingTop: "20px", width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/4c75f2c4-chef-gang.mp4" muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />


        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>
        

      <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageworkUrl={writerscafe.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={abbys.title}
                            description={abbys.description}
                            pageUrl={abbys.pageUrl}
                            imageworkUrl={abbys.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={hilton.title}
                            description={hilton.description}
                            pageUrl={hilton.pageUrl}
                            imageworkUrl={hilton.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

      <Footer />
    </>
  )
}

export default Vapho
