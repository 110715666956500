import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { intellect, waycool, futbank } from '../Model/Project'

const Vinveli = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Vinveli</h2>
                <h3 className='text-animate'>Towards a smarter<br className="d-none d-lg-block" /> and safer future.</h3>
            </div>
        </div>
        <div className="vinveli-bg"></div>

        <div className="container vinveli-over">
            <div className="row">
                <div className="col-lg-1"></div>
                <div className="col-lg-4 col-md-6 vinv-over">
                    <h2>Setting the tone for<br className="d-none d-lg-block d-md-block" /> India’s first commerical<br className="d-none d-lg-block d-md-block" /> firearms facility </h2>
                </div>
                <div className="col-lg-6 col-md-6 vinv-over">
                    <p>Tasked with rebranding and creating communication for Vinveli in India, the trailblazer in the fields of robotics, automation and logistical infrastructure. Vinveli makes indigenous UAVs or drones for Special Forces under the Ministry of Home Affairs, such as the National Security Guard and the Central Reserve Police Force, and the Ministry of Defence. Vinveli was now set to bring the international giants CZ to India, with a range of firearms that had never before been available in the country. Together, CZ and Vinveli were set to change the face of firearms in India.</p>
                </div>
                <div className="col-lg-1"></div>
            </div>
        </div>

        <div className="vinv-video">
            <div className="container text-animate">
                <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/38c915c8-vinveli-logo-animation-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
            </div>
        </div>

        <div className="container text-animate text-center">
            <img className="img-fluid vinv-image" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9707da1b-groufdsp-404-1.png" alt="Vinveli" />
        </div>

        <div className="container">
            <div className="vinv-cont">
                <h2>The new face of Defence and Space Technology thats made in India</h2>
                <p>Our task was to create a new identity for Vinveli, starting from the logo. A logo that showcased it’s vision to be at the forefront of the cutting edge technology revolution in India. The logo we created incorporated the cross hairs of a gun to showcase the precision that the brand brings to all their offerings along with a star, to represent its dominance over the skies with its drones and anti-drone technology.</p>
            </div>
        </div>
        
        <div className="vinv-note">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2e56462a-stationery-mocsfdkups-top-angle-view-1-1.png" alt="Vinveli" />
                    </div>
                    <div className="col-lg-6 col-md-6 vinv-note-cont">
                        <div className="d-block">
                            <p>From the stationery to all the brand collaterals, we<br className="d-none d-lg-block" /> incorporated elements of technology and innovation<br className="d-none d-lg-block" /> through the use of colours, fonts and imagery. We also<br className="d-none d-lg-block" /> created a brochure for them, which showcased Vinveli as<br className="d-none d-lg-block" /> the new face of defence and space technology that’s<br className="d-none d-lg-block" /> made in India.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container text-animate text-center vinv-note-img">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/350e27b7-view_02fds-1-1.png" alt="Vinveli" />
        </div>

        <div className="container text-animate text-center vinv-note-img">
            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c0a188fc-view_fsd03-1-1.png" alt="Vinveli" />
        </div>

        <div className="vinveli-bg1"></div>

        <div className="vinv-pisto">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="vinv-pistol">
                            <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8ca6f9e6-gun-1dfs-1-1.png" alt="Vinveli" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 vinv-pisto-cont">
                        <div className="d-block">
                            <p>As Vinveli is an international technology solutions provider specializing in the aerospace and robotics industry, they are heavily influenced by automation and efficiency. Their goal is to create a future that relies on machine intelligence to reduce human effort. To this end, we wanted to showcase all their strengths through their social media, building up a brand image of a company that’s driven by innovation at every level.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container vinv-pistol-img">
            <div className="row">
                <div className="col-lg-6 col-md-6 vinv-pisto-img p-0">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a3a721cf-groudsfp-406-1.png" alt="Vinveli" />
                </div>
                <div className="col-lg-6 col-md-6 vinv-pisto-img p-0">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/f9272155-group-sfd405-1.png" alt="Vinveli" />
                </div>
            </div>
        </div>

        <div className="container vinv-pisto-para">
            <p>For the firearms launch in India, we wanted to tread lightly as it was a topic that is hotly debated around the world. While gun enthusiasts couldn’t wait for the launch of CV weapons in India, we wanted to use the network of existing license owners to create excitement for the launch before we reached out to the public at large. <br className="d-lg-none d-md-nome" /><br className="d-lg-none d-md-nome" /> We created a teaser campaign to build curiosity for the launch and then followed this up with extensive social media and digital assets which spoke about the precision, reliability and accuracy of the best firearms in the world, that were now being launched in India by Vinveli. Through this approach, we were able to attract the right attention to the launch and create a buzz in the market as well, while creating a sustained interest in the brand.</p>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 vinv-social">
                    <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e1c58fcc-drone-cover-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 vinv-social">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/637c733a-ig-paid-social-square-ipshone-x-1.png" alt="Vinveli" />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-6 vinv-social">
                    <video src='https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9a3e5526-man-with-gun-1.mp4' muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />
                </div>
                <div className="col-lg-6 col-md-6 vinv-social">
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b18539e3-ig-paid-social-square-siphone-x-1.png" alt="Vinveli" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={intellect.title}
                            description={intellect.description}
                            pageUrl={intellect.pageUrl}
                            imageworkUrl={intellect.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={waycool.title}
                            description={waycool.description}
                            pageUrl={waycool.pageUrl}
                            imageworkUrl={waycool.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageworkUrl={futbank.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Vinveli