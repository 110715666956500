import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Workpages from "../Components/Workpages";
import {
  mypersona,
  vinveli,
  freshey,
  intellect,
  bayleaf,
  sequencelounge,
  airportzo,
  writerscafe,
  asianpaints,
  privateearth,
  ewallet,
  lexotique,
  mehtajewellery,
  bharathihomes,
  curiohh,
  vinveliweb,
  gbr,
  waycool,
  thexanadu,
  authorcafe,
  oswinply,
  vinitahospital,
  desidi,
  kitchenji,
  shuddha,
  curiohhtravel,
  amelies,
  kokumstore,
  sunnybeehoney,
  sunnybee,
  abbys,
  hilton,
  sunnybeebranding,
  loanoriginationsystem,
  sunnybeebread,
  transformativedigital,
  aniva,
  vapho,
  futbank,
  mahasaghar,
  flyet,
  sunnybeeLatest,
  futbankLatest,
  ewalletLatest,
} from "../Model/Project";
// import $ from 'jquery'

// var filterActive;

// function filterCategory(category) {
//     if (filterActive != category) {

//         // reset results list
//         $('.filter-cat-results .f-cat').removeClass('active');

//         // elements to be filtered
//         $('.filter-cat-results .f-cat')
//             .filter('[data-cat="' + category + '"]')
//             .addClass('active');

//         // reset active filter
//         filterActive = category;
//     }
// }

// $('.f-cat').addClass('active');

// $('.filtering select').change(function() {
//     if ($(this).val() == 'cat-all') {
//         $('.filter-cat-results .f-cat').addClass('active');
//         filterActive = 'cat-all';
//     } else {
//         filterCategory($(this).val());
//     }
// });

// $(document).ready(function(){
//   $(".filter-feat").click(function(){
//       $(".brand, .camp, .web, .digi, .pack, .social, .film").fadeOut(200);
//       $(".feat").fadeIn(200);
//   });
//   $(".filter-brand").click(function(){
//       $(".feat, .camp, .web, .digi, .pack, .social, .film").fadeOut(200);
//       $(".brand").fadeIn(200);
//   });
//   $(".filter-camp").click(function(){
//       $(".feat, .brand, .web, .digi, .pack, .social, .film").fadeOut(200);
//       $(".camp").fadeIn(200);
//   });
//   $(".filter-web").click(function(){
//       $(".feat, .brand, .camp, .digi, .pack, .social, .film").fadeOut(200);
//       $(".web").fadeIn(200);
//   });
//   $(".filter-digi").click(function(){
//       $(".feat, .brand, .camp, .web, .pack, .social, .film").fadeOut(200);
//       $(".digi").fadeIn(200);
//   });
//   $(".filter-pack").click(function(){
//       $(".feat, .brand, .camp, .web, .digi, .social, .film").fadeOut(200);
//       $(".pack").fadeIn(200);
//   });
//   $(".filter-social").click(function(){
//       $(".feat, .brand, .camp, .web, .digi, .pack, .film").fadeOut(200);
//       $(".social").fadeIn(200);
//   });
//   $(".filter-film").click(function(){
//       $(".feat, .brand, .camp, .web, .digi, .pack, .social").fadeOut(200);
//       $(".film").fadeIn(200);
//   });
//   $(".filter-all").click(function(){
//       $(".feat, .brand, .camp, .web, .digi, .pack, .social, .film").fadeIn(200);
//   });
// });

// $('#medicalHistoryFilter').on('change', function() {
//   let selectedFitler = $(this).val();
//   if (selectedFitler.startsWith('panel-')) {
//     $('#accordion .panel').hide();
//     $('#' + selectedFitler).parent().show();
//   } else {
//     $('#accordion .panel').show();
//   }
// });

const Work = () => {
  return (
    <>
      <Header />

      <div className="works">
        <div className="container work-ban-cont text-animate">
          <h2>
            Creative solutions driven <br className="d-none d-lg-block" /> by
            brand purpose.
          </h2>
        </div>
        <div className="container">
          <div className="border-bb"></div>
        </div>
      </div>

      {/* <div class="container d-none d-lg-block d-md-block">
            <div class="row">
                <div class="col-md-12">
                    <ul className="p-0">
                        <li className="aai"><a href="#/" class="filter-all active aa">All Project</a></li><br />
                        <li className="aai"><a href="#/" class="filter-feat aa">Featured</a></li>
                        <li className="aai"><a href="#/" class="filter-brand aa">Branding</a></li>
                        <li className="aai"><a href="#/" class="filter-camp aa">Campaigns</a></li>
                        <li className="aai"><a href="#/" class="filter-web aa">Websites</a></li><br />
                        <li className="aai"><a href="#/" class="filter-digi aa">Digital</a></li>
                        <li className="aai"><a href="#/" class="filter-pack aa">Packaging</a></li>
                        <li className="aai"><a href="#/" class="filter-social aa">Social Media</a></li>
                        <li className="aai"><a href="#/" class="filter-film aa">Films</a></li>
                    </ul>
                </div>
            </div>
        </div> */}

      {/* <div className="container dropdown d-lg-none d-md-none">
            <button className="btn btn-secondary dropdown-toggle filter-all active aa" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            All Project
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a href="#/" className="dropdown-item filter-feat aa">Featured</a>
                <a href="#/" className="dropdown-item filter-brand aa">Branding</a>
                <a href="#/" className="dropdown-item filter-camp aa">Campaigns</a>
                <a href="#/" className="dropdown-item filter-web aa">Websites</a>
                <a href="#/" className="dropdown-item filter-digi aa">Digital</a>
                <a href="#/" className="dropdown-item filter-pack aa">Packaging</a>
                <a href="#/" className="dropdown-item filter-social aa">Social Media</a>
                <a href="#/" className="dropdown-item filter-film aa">Films</a>
            </div>
        </div> */}

      {/* <div className="d-lg-none d-md-none">
          <select class="form-control" id="medicalHistoryFilter">
            <option className="filter-all active aa">All Project</option>
            <option value="panel-1">panel-1</option>
            <option value="panel-2">panel-2</option>
            <option value="panel-3">panel-3</option>
            <option value="panel-4">panel-4</option>
            <option value="panel-5">panel-5</option>
            <option value="panel-6">panel-6</option>
            <option value="panel-7">panel-7</option>
            <option value="panel-8">panel-8</option>
          </select>

          <div class="panel-group" id="accordion">

            <div class="panel panel-default">
              <div id="panel-1">This is panel 1</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-2">This is panel 2</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-3">This is panel 3</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-4">This is panel 4</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-5">This is panel 5</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-6">This is panel 6</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-7">This is panel 7</div>
            </div>

            <div class="panel panel-default">
              <div id="panel-8">This is panel 8</div>
            </div>
          </div>
        </div> */}

      {/* <div className="container">
          <div class="filtering">
              <div class="filter-cat row">
                  <div class="col col-md-3 col-xs-6">
                      <select class="form-control">
                          <option value="cat-all">Show all</option>
                          <option value="cat-red">Red</option>
                          <option value="cat-blue">Blue</option>
                          <option value="cat-green">Green</option>
                      </select>
                  </div>
              </div>
              <div class="row filter-cat-results">
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-red">First</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-red">Second</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-blue">Third</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-green">Fourth</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-red">Fifth</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-blue">Sixth</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-blue">Seventh</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-green">Eighth</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-blue">Ninth</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-green">Tenth</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-red">Eleventh</div>
                  <div class="col col-md-3 col-xs-6 f-cat" data-cat="cat-green">Twelfth</div>
              </div>
          </div>
        </div> */}

      <div className="container">
        <div className="filtering">
          {/* <div class="filter-cat row">
                  <div class="col col-md-3 col-xs-6">
                      <select class="form-control">
                          <option value="cat-all">Show all</option>
                          <option value="cat-red">Red</option>
                          <option value="cat-blue">Blue</option>
                          <option value="cat-green">Green</option>
                      </select>
                  </div>
              </div> */}

          <div className="row works-padd filter-gallery filter-cat-results">
            <div
              className="col-lg-6 col-md-6 works-padd feat all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={ewalletLatest.title}
                description={ewalletLatest.description}
                pageUrl={ewalletLatest.pageUrl}
                imageworkUrl={ewalletLatest.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd feat all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={futbankLatest.title}
                description={futbankLatest.description}
                pageUrl={futbankLatest.pageUrl}
                imageworkUrl={futbankLatest.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd feat all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={sunnybeeLatest.title}
                description={sunnybeeLatest.description}
                pageUrl={sunnybeeLatest.pageUrl}
                imageworkUrl={sunnybeeLatest.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd feat all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={flyet.title}
                description={flyet.description}
                pageUrl={flyet.pageUrl}
                imageworkUrl={flyet.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd feat all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={mahasaghar.title}
                description={mahasaghar.description}
                pageUrl={mahasaghar.pageUrl}
                imageworkUrl={mahasaghar.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd feat all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={mypersona.title}
                description={mypersona.description}
                pageUrl={mypersona.pageUrl}
                imageworkUrl={mypersona.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd brand all teal f-cat"
              data-cat="cat-blue">
              <Workpages
                title={vinveli.title}
                description={vinveli.description}
                pageUrl={vinveli.pageUrl}
                imageworkUrl={vinveli.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd camp all pink f-cat"
              data-cat="cat-green">
              <Workpages
                title={freshey.title}
                description={freshey.descr}
                pageUrl={freshey.pageUrl}
                imageworkUrl={freshey.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd web all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={intellect.title}
                description={intellect.description}
                pageUrl={intellect.pageUrl}
                imageworkUrl={intellect.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd digi all teal f-cat"
              data-cat="cat-blue">
              <Workpages
                title={bayleaf.title}
                description={bayleaf.description}
                pageUrl={bayleaf.pageUrl}
                imageworkUrl={bayleaf.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd pack all pink f-cat"
              data-cat="cat-green">
              <Workpages
                title={sequencelounge.title}
                description={sequencelounge.description}
                pageUrl={sequencelounge.pageUrl}
                imageworkUrl={sequencelounge.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd social all blue f-cat"
              data-cat="cat-red">
              <Workpages
                title={airportzo.title}
                description={airportzo.description}
                pageUrl={airportzo.pageUrl}
                imageworkUrl={airportzo.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd film all teal f-cat"
              data-cat="cat-blue">
              <Workpages
                title={writerscafe.title}
                description={writerscafe.description}
                pageUrl={writerscafe.pageUrl}
                imageworkUrl={writerscafe.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd feat all pink f-cat"
              data-cat="cat-green">
              <Workpages
                title={asianpaints.title}
                description={asianpaints.description}
                pageUrl={asianpaints.pageUrl}
                imageworkUrl={asianpaints.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd brand all purple f-cat"
              data-cat="cat-red">
              <Workpages
                title={privateearth.title}
                description={privateearth.description}
                pageUrl={privateearth.pageUrl}
                imageworkUrl={privateearth.imageworkUrl}
              />
            </div>

            {/* <div
              className="col-lg-6 col-md-6 works-padd camp all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={ewallet.title}
                description={ewallet.description}
                pageUrl={ewallet.pageUrl}
                imageworkUrl={ewallet.imageworkUrl}
              />
            </div> */}
            <div
              className="col-lg-6 col-md-6 works-padd web all purple f-cat"
              data-cat="cat-green">
              <Workpages
                title={lexotique.title}
                description={lexotique.description}
                pageUrl={lexotique.pageUrl}
                imageworkUrl={lexotique.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd digi all f-cat"
              data-cat="cat-red">
              <Workpages
                title={mehtajewellery.title}
                description={mehtajewellery.description}
                pageUrl={mehtajewellery.pageUrl}
                imageworkUrl={mehtajewellery.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd pack all purple f-cat"
              data-cat="cat-blue">
              <Workpages
                title={bharathihomes.title}
                description={bharathihomes.description}
                pageUrl={bharathihomes.pageUrl}
                imageworkUrl={bharathihomes.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd social all f-cat"
              data-cat="cat-green">
              <Workpages
                title={curiohh.title}
                description={curiohh.description}
                pageUrl={curiohh.pageUrl}
                imageworkUrl={curiohh.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd film all f-cat"
              data-cat="cat-red">
              <Workpages
                title={vinveliweb.title}
                description={vinveliweb.description}
                pageUrl={vinveliweb.pageUrl}
                imageworkUrl={vinveliweb.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd feat all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={gbr.title}
                description={gbr.description}
                pageUrl={gbr.pageUrl}
                imageworkUrl={gbr.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd brand all f-cat"
              data-cat="cat-green">
              <Workpages
                title={waycool.title}
                description={waycool.description}
                pageUrl={waycool.pageUrl}
                imageworkUrl={waycool.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd camp all f-cat"
              data-cat="cat-red">
              <Workpages
                title={thexanadu.title}
                description={thexanadu.description}
                pageUrl={thexanadu.pageUrl}
                imageworkUrl={thexanadu.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd web all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={abbys.title}
                description={abbys.description}
                pageUrl={abbys.pageUrl}
                imageworkUrl={abbys.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd digi all f-cat"
              data-cat="cat-green">
              <Workpages
                title={authorcafe.title}
                description={authorcafe.description}
                pageUrl={authorcafe.pageUrl}
                imageworkUrl={authorcafe.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd pack all f-cat"
              data-cat="cat-red">
              <Workpages
                title={oswinply.title}
                description={oswinply.description}
                pageUrl={oswinply.pageUrl}
                imageworkUrl={oswinply.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd social all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={vinitahospital.title}
                description={vinitahospital.description}
                pageUrl={vinitahospital.pageUrl}
                imageworkUrl={vinitahospital.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd film all f-cat"
              data-cat="cat-green">
              <Workpages
                title={desidi.title}
                description={desidi.description}
                pageUrl={desidi.pageUrl}
                imageworkUrl={desidi.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd feat all f-cat"
              data-cat="cat-red">
              <Workpages
                title={kitchenji.title}
                description={kitchenji.description}
                pageUrl={kitchenji.pageUrl}
                imageworkUrl={kitchenji.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd brand all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={shuddha.title}
                description={shuddha.description}
                pageUrl={shuddha.pageUrl}
                imageworkUrl={shuddha.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd camp all f-cat"
              data-cat="cat-green">
              <Workpages
                title={curiohhtravel.title}
                description={curiohhtravel.description}
                pageUrl={curiohhtravel.pageUrl}
                imageworkUrl={curiohhtravel.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd web all f-cat"
              data-cat="cat-red">
              <Workpages
                title={amelies.title}
                description={amelies.description}
                pageUrl={amelies.pageUrl}
                imageworkUrl={amelies.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd digi all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={kokumstore.title}
                description={kokumstore.description}
                pageUrl={kokumstore.pageUrl}
                imageworkUrl={kokumstore.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd pack all f-cat"
              data-cat="cat-green">
              <Workpages
                title={sunnybeehoney.title}
                description={sunnybeehoney.description}
                pageUrl={sunnybeehoney.pageUrl}
                imageworkUrl={sunnybeehoney.imageworkUrl}
              />
            </div>

            {/* <div
              className="col-lg-6 col-md-6 works-padd social all f-cat"
              data-cat="cat-red">
              <Workpages
                title={sunnybee.title}
                description={sunnybee.description}
                pageUrl={sunnybee.pageUrl}
                imageworkUrl={sunnybee.imageworkUrl}
              />
            </div> */}
            <div
              className="col-lg-6 col-md-6 works-padd film all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={hilton.title}
                description={hilton.description}
                pageUrl={hilton.pageUrl}
                imageworkUrl={hilton.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd feat all f-cat"
              data-cat="cat-green">
              <Workpages
                title={sunnybeebranding.title}
                description={sunnybeebranding.description}
                pageUrl={sunnybeebranding.pageUrl}
                imageworkUrl={sunnybeebranding.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd brand all f-cat"
              data-cat="cat-red">
              <Workpages
                title={loanoriginationsystem.title}
                description={loanoriginationsystem.description}
                pageUrl={loanoriginationsystem.pageUrl}
                imageworkUrl={loanoriginationsystem.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd camp all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={sunnybeebread.title}
                description={sunnybeebread.description}
                pageUrl={sunnybeebread.pageUrl}
                imageworkUrl={sunnybeebread.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd web all f-cat"
              data-cat="cat-green">
              <Workpages
                title={transformativedigital.title}
                description={transformativedigital.description}
                pageUrl={transformativedigital.pageUrl}
                imageworkUrl={transformativedigital.imageworkUrl}
              />
            </div>

            <div
              className="col-lg-6 col-md-6 works-padd digi all f-cat"
              data-cat="cat-red">
              <Workpages
                title={aniva.title}
                description={aniva.description}
                pageUrl={aniva.pageUrl}
                imageworkUrl={aniva.imageworkUrl}
              />
            </div>
            <div
              className="col-lg-6 col-md-6 works-padd pack all f-cat"
              data-cat="cat-blue">
              <Workpages
                title={vapho.title}
                description={vapho.description}
                pageUrl={vapho.pageUrl}
                imageworkUrl={vapho.imageworkUrl}
              />
            </div>

            {/* <div
              className="col-lg-6 col-md-6 works-padd social all f-cat"
              data-cat="cat-green">
              <Workpages
                title={futbank.title}
                description={futbank.description}
                pageUrl={futbank.pageUrl}
                imageworkUrl={futbank.imageworkUrl}
              />
            </div> */}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Work;
