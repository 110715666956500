import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Seeourthinking from "../Components/Seeourthinking";
import { sunnybeeLatest, flyet, futbankLatest } from "../Model/Project";

const EwalletLatest = () => {
  return (
    <>
      <Header />

      <div className="my-perso-head">
        <div className="container my-perso-head">
          <h2 className="text-animate">E-Wallet</h2>
          <h3 className="text-animate">
            Simplify Your Spending with
            <br className="d-none d-lg-block" /> Our E-Wallet Solution.
          </h3>
        </div>
      </div>

      <div className="container text-animate intellect-bann-img">
        <img
          className="img-fluid d-md-none"
          src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-1-m2.png"
          alt="ewallet-banner"
        />
        <img
          className="img-fluid d-none d-md-block"
          src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-1.png"
          alt="ewallet-banner"
        />
      </div>

      <div className="container vinveli-over">
        <div className="row">
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4 col-md-6 vinv-over">
            <h2 className="text-animate">
              What went into the
              <br className="d-none d-lg-block d-md-block" /> creation of the
              app?
            </h2>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-6 col-md-6 vinv-over">
            <p className="text-animate">
              Our E-Wallet Solution not only connects you with real-time
              information and suggestions, but also helps you track your
              spending and define your financial goals. Stay on top of your
              finances with ease and make informed decisions about your money.
              With its user-friendly interface and advanced tracking
              capabilities, our E-Wallet makes managing your finances a breeze.
            </p>
          </div>

          <div className="col-lg-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/e-walet-video-1.mp4"
              alt="ewallet"></video>
          </div>
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/e-walet-video-2.mp4"
              alt="intellect"></video>
          </div>
        </div>

        <div className="container vinveli-over">
          <div className="intellect-para">
            <p className="text-animate">
              A variety of widgets that display key information about your
              finances, including your spending habits, transaction history, and
              financial goals.
            </p>
          </div>
        </div>

        <div className="container text-animate">
          <img
            className="img-fluid ewallet-absolute-img"
            src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-2.png"
            alt="ewallet-banner"
          />
        </div>

        <div className="container text-animate ewallet-img-container">
          <img
            className="img-fluid"
            src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-3s2.png"
            alt="ewallet-banner"
          />
        </div>

        <div className="container py-md-5">
          <div className="row e-wallet-lg">
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Track your Goals</h5>
                <p className="text-animate">
                  At Future, we understand the importance of goal setting in
                  financial management. We included a feature that enables users
                  to establish financial targets and monitor their progress.
                </p>
                <p className="text-animate">
                  This function facilitates saving for specific objectives such
                  as vacations, down payments, or emergency funds through
                  automated transfers to a dedicated savings account. The
                  visually engaging interface of the user's progress towards
                  their goal serves to provide motivation and keep them on track
                  towards their financial objectives.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-4-2.png"
                alt="ewallet-banner"
              />
            </div>
          </div>
        </div>

        <div className="container text-animate pt-4">
          <img
            className="img-fluid"
            // style={{ padding: "0 10px" }}
            src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-5.png"
            alt="intellect-banner"
          />
        </div>

        <div className="container vinveli-over">
          <div className="row">
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-lg-4 col-md-6 vinv-over">
              <h2 className="text-animate">
                Effortless Payments <br className="d-none d-lg-block" />
                from Your Wrist
              </h2>
            </div>
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-lg-6 col-md-6 vinv-over">
              <p className="text-animate">
                Our app allows you to transfer money with just a few taps on
                your watch, making it easier than ever before. No more fumbling
                for your phone or waiting in line at the bank. With our watch
                app you can complete transactions instantly and on-the-go.
              </p>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>

        <div className="container">
          <div className="row e-wallet-lg flyet-card-reverse pb-md-5">
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-6.png"
                alt="ewallet-banner"
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Track your Spends</h5>
                <p className="text-animate">
                  Our advanced tracking features allow you to monitor your
                  expenses in real-time, giving you a complete overview of your
                  financial status. From daily purchases to recurring bills,
                  you'll have all the information you need to make informed
                  decisions about your money. With our user-friendly interface
                  and intuitive tracking tools, keeping track of your spending
                  has never been simpler.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container text-animate pt-md-5">
        <img
          className="img-fluid"
          // style={{ padding: "0 10px" }}
          src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-7.png"
          alt="intellect-banner"
        />
      </div>

      <div className="container vinveli-over">
        <div className="row">
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4 col-md-6 vinv-over">
            <h2 className="text-animate">
              Customisable
              <br className="d-none d-lg-block" />
              Dashboard
            </h2>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-6 col-md-6 vinv-over">
            <p className="text-animate">
              At Future, we strive to continuously improve and enhance our
              products. With that in mind, we have designed a new home screen
              for our e-wallet app, which acts as a central hub for users. The
              UI allows for easy access to vital information, providing a
              comprehensive view of all critical details in one place. With this
              improved dashboard, users can quickly initiate payments and
              efficiently manage their accounts.
            </p>
          </div>

          <div className="col-lg-1"></div>
        </div>
      </div>

      <div className="container pb-4">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-8.png"
              alt="ewallet"
            />
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://ik.imagekit.io/future/assets/tr:f-auto/ewallet-img-9.png"
              alt="ewallet"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div>
        <div className="container sotia text-animate">
          <h2>See our thinking in action.</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={sunnybeeLatest.title}
                description={sunnybeeLatest.description}
                pageUrl={sunnybeeLatest.pageUrl}
                imageworkUrl={sunnybeeLatest.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={flyet.title}
                description={flyet.description}
                pageUrl={flyet.pageUrl}
                imageworkUrl={flyet.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={futbankLatest.title}
                description={futbankLatest.description}
                pageUrl={futbankLatest.pageUrl}
                imageworkUrl={futbankLatest.imageworkUrl}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default EwalletLatest;
