import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { ewallet, authorcafe, futbank } from '../Model/Project'


const Loanoriginationsystem = () => {
  return (
    <>

        <Header />

        <div className="container los-dash-img">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-cont-position">
                    <div className="d-block fut-bank-cont">
                        <h4 className='text-animate'>NBW Bank</h4>
                        <h3 className='text-animate'>Developing a smart banking <br className="d-none d-lg-block" /> framework that clients and banks <br className="d-none d-lg-block" /> can use with ease.</h3>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-img text-animate"              >
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/66df89e7-loan-origination-main.png" alt="Loan-origination-system" />
                </div>
            </div>
        </div>

        <div className="container fut-bank-overview">
            <div className="row">
                <div className="col-lg-6 col-md-6 fut-bank-abov">
                    <h5 className='text-animate'>ABOUT LOAN ORIGINATION SYSTEM</h5>
                    <p className='text-animate'>LOS is a smart banking framework that helps the relationship manager to interact with clients and manage portfolios with ease.</p>
                </div>
                <div className="col-lg-6 col-md-6 fut-bank-obj">
                    <h5 className='text-animate'>OBJECTIVE</h5>
                    <p className='text-animate'>To create a unique dashboard that is modular, so that it can store information about all customer loan portfolios and be retrievable quickly, and easily.</p>
                </div>
            </div>
        </div>

        <div className="vinveli-wf">
            <div className="container vinveli-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
            </div>

            <div className="container">
                <div className="los-wf-img text-animate"         >
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/776081b2-loan-origination-wireframe.png" alt="Loan-origination-system" />
                </div>
                <div className="los-wf-img0 text-animate"              >
                    <img className="img-fluid" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/f3bc11bf-loan-origination-wireframe2.png" alt="Loan-origination-system" />
                </div>
            </div>
        </div>

        <div className="los-cus-dash">
            <div className="container los-cus-dash-cont">
                <h5 className='text-animate'>Customisable Dashboard</h5>
                <p className='text-animate'>Highly customizable dashboard for relationship managers to tailor loans <br className="d-none d-lg-block" /> to different customers easily.</p>

                <div className="los-cus-dash0 text-animate"              >
                    <img className="img-fluid los-cus-dash-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/017bb206-loan-origination-ui.png" alt="Loan-origination-system" />
                </div>
            </div>
        </div>

        <div className="los-cus-dash-sec">
            <div className="container los-cus-sec-cont">
                <div className="row">
                    <div className="col-lg-6 col-md-6 los-cus-sec-img text-animate"              >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/0b2d6edb-loan-origination-target.png" alt="Loan-origination-system" />
                    </div>
                    <div className="col-lg-6 col-md-6 fut-bank-ld-cont">
                        <div className="d-block">
                            <h5 className='text-animate'>Targets</h5>
                            <p className='text-animate'>A quick overview allows managers to keep track of <br className="d-none d-lg-block" /> business generated, targets to achieve and loan status.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container los-cus-sec-cont0">
                <div className="row los-cus-sec-col">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                        <div className="d-block fut-bank-sec-cont">
                            <h5 className='text-animate'>Smart Search</h5>
                            <p className='text-animate'>The loan Origination system analyzes the <br className="d-none d-lg-block" /> keywords you type and shows the relevant data <br className="d-none d-lg-block" /> on the search bar.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 los-cus-sec-img text-animate"              >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/9605a99d-loan-origination-smart.png" alt="Loan-origination-system" />
                    </div>
                </div>
            </div>

            <div className="container los-cus-sec-cont1">
                <div className="row">
                    <div className="col-lg-6 col-md-6 los-cus-sec-img text-animate"              >
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/06/a2d42ef4-loan-origination-quick.png" alt="Loan-origination-system" />
                    </div>
                    <div className="col-lg-6 col-md-6 fut-bank-ld-cont">
                        <div className="d-block">
                            <h5 className='text-animate'>Quick Reminders</h5>
                            <p className='text-animate'>Relationship managers don’t need to fear missing out on <br className="d-none d-lg-block" /> meetings. The LOS has built-in calendars so managers <br className="d-none d-lg-block" /> can plan and schedule client meetings effectively.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={ewallet.title}
                            description={ewallet.description}
                            pageUrl={ewallet.pageUrl}
                            imageworkUrl={ewallet.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageworkUrl={authorcafe.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={futbank.title}
                            description={futbank.description}
                            pageUrl={futbank.pageUrl}
                            imageworkUrl={futbank.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>
            


        <Footer />
    </>
  )
}

export default Loanoriginationsystem