import React from "react";
import ContactButton from "../Pages/Form";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="foot-cont">
            <h2>Let’s talk about you</h2>
            <div className="footer__btn">
              {/* <a href="/contact">Get in touch</a> */}
              <ContactButton />
            </div>
          </div>
        </div>
      </div>

      <div className="container d-none">
        <div className="border-bb"></div>
      </div>

      <div className="container">
        <div className="row footer-addr">
          <div className="col-lg-4 col-md-4">
            <h2>Chennai (Head Office)</h2>
            <h3>
              15/4, 3rd Floor, Haddows Lane, <br />
              Haddows Road, Nungambakkam, <br />
              Chennai - 600006
            </h3>
          </div>
          <div className="col-lg-4 col-md-4">
            <h3>For Business Enquiries</h3>
            <h2>
              <a
                className="main-mail"
                href="mailto:aishwarya@fingerprintscreative.com">
                aishwarya@fingerprintscreative.com
              </a>
              <br />{" "}
              <a className="main-tel" href="tel:+919176111675">
                +91 91761 11675
              </a>
            </h2>
          </div>
          <div className="col-lg-4 col-md-4">
            <h3>For Vendors</h3>
            <h2>
              <a
                className="main-mail2"
                href="mailto:mini@fingerprintscreative.com">
                mini@fingerprintscreative.com
              </a>
            </h2>
          </div>
        </div>

        <div className="row foot-li">
          <div className="col-lg-9 col-md-9">
            <h2>
              <a href="/about">About</a>
            </h2>
            <h2>
              <a href="/work">Work</a>
            </h2>
            <h2>
              <a href="/services">Services</a>
            </h2>
            <h2>
              <a href="/careers">Careers</a>
            </h2>
            {/* <h2><a href="/contact">Contact</a></h2> */}
          </div>
          <div className="col-lg-3 col-md-3">
            <h2>
              <a
                href="https://www.instagram.com/fingerprints_creative/"
                target="blank">
                Instagram
              </a>
            </h2>
            <h2>
              <a
                href="https://www.facebook.com/Fingerprintscreative/"
                target="blank">
                Facebook
              </a>
            </h2>
            <h2>
              <a
                href="https://www.youtube.com/user/fingerprintscreative"
                target="blank">
                Youtube
              </a>
            </h2>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div className="container">
        <div className="row footer-copy">
          <div className="col-lg-4 col-md-6 foot-copy">
            <h2>2024 Fingerprints Creative Private Limited.</h2>
          </div>
          <div className="col-lg-6 col-md-2"></div>
          <div className="col-lg-2 col-md-4 d-flex justify-content-end">
            <div className="foot-img d-none d-lg-block d-md-block">
              <img
                src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/f5377b44-tiger.svg"
                alt="Finger-prints"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
