import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { vinveli, writerscafe, waycool } from '../Model/Project'

const Vinithahospital = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Vinita Hospitals</h2>
                <h3 className='text-animate'>Creating an icon for an<br className="d-lg-none d-md-none" /> iconic brand.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative text-center">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/07e4cd9c-whatsapp-image-2021-10-08-at-6.10-1-1.png" alt="intellect-banner" />
        </div>

        <div className="container curiohh-over">
            <div className="row">
                <div className="col-lg-6 col-md-6 vinita-resp">
                    <img className="img-fluid" style={{width: "60%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2d27d2b9-sizerartboard-3-1-1.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-6 col-md-6 curiohh-cont vinita-resp">
                    <p>Vinita Hospitals, a super speciality kidney and healthcare centre needed a new brand identity to mark its transition from the parent brand, VGSC, to a stand-alone hospital. The brand needed to be recognised instantly, yet wanted to retain some brand recall from the original VGSC brand mark that people could identify with.</p>
                </div>
            </div>
        </div>

        <div className="container curiohh-over">
            <div className="row vinita-column">
                <div className="col-lg-6 col-md-6 curiohh-cont vinita-resp">
                    <p>The new logo we created celebrates the care, vision and approachable nature of the parent company yet refreshes it by incorporating a shield in the unit to represent protection and the tenet of preventive care, the founding principle of the hospital.</p>
                </div>
                <div className="col-lg-6 col-md-6 vinita-resp">
                    <img className="img-fluid" style={{width: "30%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/358a7165-asset-8@3sx-1-1.png" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="container text-animate position-relative text-center">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/680b0756-whatsapp-image-2021-10-08-at-6.10-2-1.png" alt="intellect-banner" />
        </div>
        
        <div className="container vinita-alpha">
            <div className="row">
                <div className="col-md-6 vinita-cont">
                    <div className="d-block text-center">
                        <p>We created a cohesive visual language, using the colours from VGSC while using a rounded font to represent care.</p>

                        <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/7b27601f-group-408-1.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-md-6 vinita-cont">
                    <img className="img-fluid bayleaf-ban-img vinita-wdt" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a50d3d99-asset-2-s1-1.png" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="container text-animate position-relative text-center vinita-z">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d4628b45-dffdss-1.png" alt="intellect-banner" />
        </div>

        <div className="vinita-bg">
            <div className="container vinita-bg-cont">
                <div className="vinita-bg-para">
                    <p>To gain both support and benefit from that parent brand, we created sub-brands that tie back to the parent brand’s qualities, values, and message, while also having their own unique qualities. Consumers who trust the main parent brand are more likely to trust products and services under the main parent brand.</p>
                </div>
            </div>

            <div className="container vinita-img-bg">
                <div className="row">
                    <div className="col-md-6 vinita-img">
                        <img className="img-fluid vinita-wdt" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/af31b94e-sddf-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-6 vinita-img">
                        <img className="img-fluid vinita-wdt" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2d0a8d03-jhjh-1.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 vinita-img">
                        <img className="img-fluid vinita-wdt" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/fe4ab6ef-fhj-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-6 vinita-img">
                        <img className="img-fluid vinita-wdt" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/09dc901b-fhjfj-1.png" alt="intellect-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="vinita-bg0">
            <div className="container vinita-bg0-img text-center d-none d-lg-block d-md-block">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9a88ec82-wgrgs-1.png" alt="intellect-banner" />
            </div>

            <div className="container">
                <div className="row vinita-img vinita-padd">
                    <div className="col-md-6 vinita-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b5a5767a-faagfg-1.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-6 vinita-img0-para">
                        <div className="d-block">
                            <h4>Wayfinding system</h4>
                            <p>
                                For new patients, hospitals can be very daunting and having an intelligent and intuitive way to navigate the space makes the entire experience in a hospital that much better. Our goal was to create as seamless an experience as possible, for both patients and staff to find their way around the premises.<br /><br />
                                We delved deep into the process of designing a wayfinding system for the hospital by starting at the very beginning, and mapping out the paths around the space during the construction stage itself.<br /><br />
                                After a few rounds of iterations, the names were finalised and we worked closely with the printers and signage vendors to create and install the wayfinding system exactly the way we had envisioned it.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container text-animate position-relative text-center">
            <img className="img-fluid vinita-chrt" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/ba0b14b1-sgfgsd-1.png" alt="intellect-banner" />

            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/58b2f028-hddh-1.png" alt="intellect-banner" />

            <div className="row bayleaf-ban-img">
                <div className="col-md-4 vinita-chrt-img">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/58f2881d-pxl_20210803_1f33155638-1.png" alt="intellect-banner" />
                </div>
                <div className="col-md-4 vinita-chrt-img">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b6a8b618-pxl_20210803_09f4529340-1.png" alt="intellect-banner" />
                </div>
                <div className="col-md-4 vinita-chrt-img">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b00eee29-hdfhdhgdhgdghfd.png" alt="intellect-banner" />
                </div>
            </div>
        </div>

        <div className="vinita-bg0 vinita-padd0">
            <div className="container vinita-tit">
                <h4>Social Media</h4>
            </div>

            <div className="container vinita-chrt-img">
                <div className="row">
                    <div className="col-md-4 vinita-chrt-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/2846c8ef-hdghdh.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-4 vinita-chrt-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/0cb13737-hdfgghd.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-4 vinita-chrt-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/bc6015d2-hdhgd.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 vinita-chrt-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/e64f35fc-hdfgdfhg.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-4 vinita-chrt-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/d57f1e0f-hdghdg.png" alt="intellect-banner" />
                    </div>
                    <div className="col-md-4 vinita-chrt-img">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/b958a7fb-stbrrt.png" alt="intellect-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinveli.title}
                            description={vinveli.description}
                            pageUrl={vinveli.pageUrl}
                            imageworkUrl={vinveli.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageworkUrl={writerscafe.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={waycool.title}
                            description={waycool.description}
                            pageUrl={waycool.pageUrl}
                            imageworkUrl={waycool.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Vinithahospital
