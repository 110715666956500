import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { sequencelounge, privateearth, airportzo } from '../Model/Project'


const GbrTMT = () => {
  return (
    <>

      <Header />
        <div className="bharathi-head">
            <div className="container bharathi-head-cont">
                <h2 className='text-animate'>GBR TMT</h2>
                <h3 className='text-animate'>Creating a strong digital foundation for GBR Metals.</h3>
            </div>
        </div>

        <div className="container gbr-img text-animate"              >
            <img className="img-fluid author-img" style={{padding: "10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/f027e797-asdfghj-1-2048x1959.png" alt="My-persona-banner" />
        </div>

        <div className="container vinveli-obj">
            <div className="vinveli-obj-cont">
                <h5 className="border-bottom text-animate"         >OBJECTIVE</h5>
                <p className='text-animate'>GBR Metals are manufacturers of one of South India' s most reliable TMT bars. With a 40-year legacy in the steel industry, their products are known for their superior quality and they were keen to increase their online brand visibility. For them, we created a website that resonated with their brand values with strong fonts, customised iconography and brand colours. The website gave information about GBR Metal’s product superiority, manufacturing process, certifications and endorsements at a glance. Through the use of illustrations, visuals and dynamic imagery, the website was informative yet engaging, and created great traction for the brand.</p>
            </div>
        </div>

        <div className="vinveli-wf">
            <div className="container vinveli-wf-cont">
                <h5 className="border-bottom text-animate"         >WIREFRAMES</h5>
            </div>

            <div className="container text-animate"              >
                <img className="img-fluid vinveli-wf-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/fab37a4a-group-2659.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="container gbr-tmt">
            <div className="grey-gbr text-animate"         >
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/c28e1319-nggggggghhfd-1.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="container-fluid orange-gbr">
            <div className="container-fluid orange-gbr2">
                <img className="img-fluid text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/02e1d581-cdvfbgnhmj-1.png" alt="My-persona-banner" />
            </div>
        </div>
     
        <div className="vinveli-wf">
            <div className="container vinveli-wf-cont">
                <h5 className="border-bottom text-animate"         >MOBILE APPLICATION</h5>
            </div>

            <div className="container">
                <img className="img-fluid vinveli-wf-img d-none d-lg-block text-animate"               src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/74536956-gbrm1-1.png" alt="My-persona-banner" />

                <div className="row d-lg-none d-xl-none">
                    <div className="col-md-12 text-center text-animate"         >
                        <img className="img-fluid vinveli-wf-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/b0858126-gbrm2-1.png" alt="My-persona-banner" />
                    </div>
                    <div className="col-md-12 text-animate"              >
                        <img className="img-fluid vinveli-wf-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/6ebfb4d7-gbrm3-1.png" alt="My-persona-banner" />
                    </div>
                </div>
            </div>

            <div className="container pt-1 text-animate"              >
                <img className="img-fluid vinveli-wf-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/02/97f1014c-0005-1-2048x1497.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sequencelounge.title}
                            description={sequencelounge.description}
                            pageUrl={sequencelounge.pageUrl}
                            imageworkUrl={sequencelounge.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={privateearth.title}
                            description={privateearth.description}
                            pageUrl={privateearth.pageUrl}
                            imageworkUrl={privateearth.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={airportzo.title}
                            description={airportzo.description}
                            pageUrl={airportzo.pageUrl}
                            imageworkUrl={airportzo.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


      <Footer />
    </>
  )
}

export default GbrTMT
