import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { writerscafe, curiohhtravel, sunnybee } from '../Model/Project'

const Curiohh = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head position-relative">
            <div className="container my-perso-head respo-cont">
                <h2 className='text-animate'>Curiohh - Branding​</h2>
                <h3 className='text-animate'>Inspiring a new generation<br className="d-none d-lg-block d-md-block" /> of adventurers.</h3>
            </div>
        </div>

        <div className="container text-animate position-relative text-center">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/04e794d4-presentation-template-f01-1-1.png" alt="intellect-banner" />
        </div>

        <div className="container curiohh-over">
            <div className="row">
                <div className="col-lg-6 col-md-6 d-flex flex-wrap align-content-center justify-content-center">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/9837fcfe-asset-1ds-1-1.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-6 col-md-6 curiohh-cont">
                    <p>Curiohh is a place where all collectibles from the quirky to the elegant, big to small, decorative to functional can all be found by eternal travellers, adventure seekers and the collectors of the curious.</p>
                </div>
            </div>
        </div>

        <div className="container text-animate position-relative text-center">
            <img className="img-fluid bayleaf-ban-img marg-top" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/782a70ba-web-badsnner-1-1.png" alt="intellect-banner" />

            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/aa85f0a6-fdsfaf-1.png" alt="intellect-banner" />

            <div className="curiohh-para">
                <p>Curiohh wanted to convey the sense of the adventure and thrills that people who love to travel always chase, hence we created a logo that represented a window, more specifically a plane window.</p>
            </div>

            <img className="img-fluid bayleaf-ban-img marg-top" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/7933385f-photofafa-3-1.png" alt="intellect-banner" />
        </div>

        {/* <div className="container curiohh-over">
            <div className="row">
                <div className="col-lg-6 col-md-6 d-flex flex-wrap align-content-center justify-content-center">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/c864000a-fdafa-1.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-6 col-md-6 curiohh-cont align-text">
                    <p>With the use of the a refreshing, calming and tranquil colour like turquoise and a font that conveyed the friendly nature of the brand, we created a brand language that brought the attributes of the company alive – the joyful nature of travel, of shared experiences and new discoveries.</p>
                </div>
            </div>
        </div>

        <div className="container text-animate position-relative text-center">
            <img className="img-fluid bayleaf-ban-img w-75" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/a5aebbff-fafafda-1.png" alt="intellect-banner" />

            <div className="curiohh-para">
                <p>There’s a whole, wide world out there to explore and through Curiohh, we<br className="d-none d-lg-block" /> wanted to give people the opportunity to experience it. The window as a<br className="d-none d-lg-block" /> device could also be used to represent any mode of transportation that takes<br className="d-none d-lg-block" /> you to different places and lets you have different experiences.</p>
            </div>
        </div>

        <div className="container curiohh-over">
            <div className="row">
                <div className="col-lg-6 col-md-6 text-center">
                        <div className="curiohh-img-width">
                            <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/8a38d498-afdfda-1.png" alt="intellect-banner" />
                        </div>
                </div>
                <div className="col-lg-6 col-md-6 d-flex flex-wrap align-content-center">
                    <div className="d-block curiohh-cont">
                        <p>The ‘vibes’ used as a design element also represented fun, energy and good vibes. Using these elements we created an array of assets for the client that we could replicate across a range of different products, retaining the same tonality and ‘feel good’ vibe.</p>
                    </div>
                </div>
            </div>
        </div> */}

        <div className="container text-animate position-relative">
            <div className="row">
                <div className="col-lg-8 col-md-8">
                    <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/02c1b9b0-fdadfafda-1.png" alt="intellect-banner" />
                </div>
                <div className="col-lg-4 col-md-4 curiohh-img0">
                    <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/5c81ff54-fdafddfsa-1.png" alt="intellect-banner" />
                </div>
            </div>

            <div className="text-center">
                <img className="img-fluid" style={{padding: "20px 10px"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2021/10/fadfdadf-1.png" alt="intellect-banner" />
            </div>   
        </div>



        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={writerscafe.title}
                            description={writerscafe.description}
                            pageUrl={writerscafe.pageUrl}
                            imageworkUrl={writerscafe.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={curiohhtravel.title}
                            description={curiohhtravel.description}
                            pageUrl={curiohhtravel.pageUrl}
                            imageworkUrl={curiohhtravel.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybee.title}
                            description={sunnybee.description}
                            pageUrl={sunnybee.pageUrl}
                            imageworkUrl={sunnybee.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Curiohh
