import React from 'react'

const Projectworks = (props) => {
  return (
    <div className="project_works">
        <a href={props.pageUrl}>
          {props.imagehomeUrl === "" ? <></> :  <img className="text-animate" style={{width: "95%"}} src={props.imagehomeUrl} alt="Finger_Prints" />}
          {/* <img className="text-animate" style={{width: "100%"}} src={props.imageUrl} alt="Finger_Prints" /> */}
          {props.videoUrl === "" ? <></> : <video className="text-animate" src={props.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" />}
          {/* <video className="text-animate" src={props.videoUrl} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" /> */}
          <h3 className='text-animate'>{props.title}</h3>
          <h4 className='text-animate'>{props.description}</h4>
        </a>
    </div>
  )
}

export default Projectworks