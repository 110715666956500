import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { kokumstore, vinveliweb, authorcafe } from '../Model/Project'

const Bayleaf = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Bayleaf​</h2>
                <h3 className='text-animate'>Social media content that’ll leave you hungry for more.</h3>
            </div>
        </div>

        <div className="container text-animate">
            <img className="img-fluid bayleaf-ban-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/24dfcfcd-banner-2.png" alt="intellect-banner" />
        </div>

        <div className="container">
            <div className="row intellect-over">
                <div className="col-lg-6 col-md-6 bayleaf-over-cont">
                    <p>Bayleaf, a fine dining and Quick Serve Restaurant, was established in 1997 and had a loyal customer base who swore by their signature dishes. We came on board to create separate campaigns for walk-ins and take away orders as well. Our task was to retain the brand’s loyalists and engage with them in a compelling way while also creating content that resonated in the already crowded restaurant space.</p>
                </div>
                <div className="col-lg-6 col-md-6 p-0">
                    <img className="img-fluid bayleaf-over-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/32241e55-1.png" alt="intellect" />
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6 bayleaf-social">
                    <div className="d-block">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/10ad2b6d-group-424.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-social">
                    <div className="d-block">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/116cc50f-group-425.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-social">
                    <div className="d-block">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/289151a2-group-426.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-social">
                    <div className="d-block">
                        <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/976aa1e8-group-427.png" alt="intellect-banner" />
                    </div>
                </div>
            </div>
        </div>

        <div className="bayleaf-bg">
            <div className="container">
                <div className="bayleaf-cont">
                    <p>We did this by bringing insight and creativity into planning of the content, creating posts that were visually appealing and drool-worthy and adding a generous dose of spontaneity and wit to every interaction. The result? We didn’t just gain followers – but rather advocates of the brand who interacted with the brand in meaningful ways.</p>

                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>Social Strategy &amp; Campaign Planning</li>
                                <li>Designing and Maintaining Social Media Platforms</li>
                                <li>Community Management</li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>Integrated Social Media Marketing Campaigns</li>
                                <li>Influencers and food bloggers outreach</li>
                                <li>Social Media Advertising</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row bayleaf-imgs">
                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/971b3700-2.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "98%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/882fc51b-5.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/6e116a94-artboard-12-100.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/178fe503-artboard-13-100.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/cfe38bed-story-2.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/9b91474e-story-4.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/91dc8ddd-artboard-8-copy-2-100-1.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/cb005256-4.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/6a33ba99-5.png" alt="intellect-banner" />
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/46be4cf9-bayleaf.png" alt="intellect-banner" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 bayleaf-img text-center">
                    <div className="d-block">
                        <img className="img-fluid" style={{width: "100%"}} src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/03/fb0c5d13-bayleaf-refer-1.png" alt="intellect-banner" />
                    </div>
                </div>
            </div>
        </div>


        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={kokumstore.title}
                            description={kokumstore.description}
                            pageUrl={kokumstore.pageUrl}
                            imageworkUrl={kokumstore.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={vinveliweb.title}
                            description={vinveliweb.description}
                            pageUrl={vinveliweb.pageUrl}
                            imageworkUrl={vinveliweb.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={authorcafe.title}
                            description={authorcafe.description}
                            pageUrl={authorcafe.pageUrl}
                            imageworkUrl={authorcafe.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </>
  )
}

export default Bayleaf