import React from 'react'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import Seeourthinking from '../Components/Seeourthinking'
import { kitchenji, shuddha, sunnybeebread } from '../Model/Project'

const Freshey = () => {
  return (
    <>
        <Header />

        <div className="my-perso-head">
            <div className="container my-perso-head">
                <h2 className='text-animate'>Freshey's</h2>
                <h3 className='text-animate'>Serving up a brand new experience.</h3>
            </div>
        </div>

        <div className="container text-animate">
            <img className="img-fluid freshey-bann-img" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/1fe7b70e-hero-1.png" alt="My-persona-banner" />
        </div>

        <div className="container freshey-overv">
            <div className="text-center freshey-img">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/9ad959b4-ffsddsframe-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-center freshey-para">
                <p>Freshey’s is a leading manufacturer of dosa and idli batter and ghee. Wanting to add more products like paneer, applam, tamarind and more to their portfolio Freshey’s recognised that in order to grow in an increasingly competitive category, they needed to refresh the brand. Fingerprints was commissioned with revitalising the brand and packaging. The brand needed a new positioning which would better reflect their vision, values and mission. In turn, the refreshed logo and packaging design needed to align with the new positioning of bringing only the freshest foods to our shelves and improve shelf stand-out.</p>
            </div>

            <div className="text-center freshey-img">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/c344faa3-group-418-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-center freshey-para">
                <p>Fingerprints developed all key touch points including logo and brand identity, packaging, social media, store collateral and advertising, creating a bold and vibrant brand identity that would drive awareness and sales.</p>
            </div>
        </div>

        <div className="container freshey-prod-img">
            <div className="text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/1a8d0477-dosa-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/9b7177f8-appalam-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/25631092-nuturo-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/f6c9ddb4-tamarind-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/da5dfda7-paneer-1.png" alt="My-persona-banner" />
            </div>

            <div className="text-animate">
                <img className="img-fluid" src="https://storage.googleapis.com/stateless-staging-fingerprints/2022/01/4a51d43a-tumuric-1.png" alt="My-persona-banner" />
            </div>
        </div>

        <div className="container">
            <div className="border-bb"></div>
        </div>

        <div>
            <div className="container sotia text-animate">
                <h2>See our thinking in action.</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={kitchenji.title}
                            description={kitchenji.description}
                            pageUrl={kitchenji.pageUrl}
                            imageworkUrl={kitchenji.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={shuddha.title}
                            description={shuddha.description}
                            pageUrl={shuddha.pageUrl}
                            imageworkUrl={shuddha.imageworkUrl}
                        />
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <Seeourthinking 
                            title={sunnybeebread.title}
                            description={sunnybeebread.description}
                            pageUrl={sunnybeebread.pageUrl}
                            imageworkUrl={sunnybeebread.imageworkUrl}
                        />
                    </div>
                </div>
            </div>
        </div>


        <Footer />
    </>
  )
}

export default Freshey