import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import Seeourthinking from "../Components/Seeourthinking";
import { ewalletLatest, sunnybeeLatest, flyet } from "../Model/Project";

const FutbankLatest = () => {
  return (
    <>
      <Header />

      <div className="my-perso-head">
        <div className="container my-perso-head">
          <h2 className="text-animate">Fut Bank</h2>
          <h3 className="text-animate">
            Creating a brand-agnostic
            <br className="d-none d-lg-block" /> banking app for the Future.
          </h3>
        </div>
      </div>

      <div className="container text-animate intellect-bann-img">
        <img
          className="img-fluid d-md-none"
          src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-mob2.png"
          alt="futbank-banner"
        />
        <img
          className="img-fluid d-none d-md-block"
          src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-1.png"
          alt="futbank-banner"
        />
      </div>

      <div className="container vinveli-over">
        <div className="row">
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-4 col-md-6 vinv-over">
            <h2 className="text-animate">
              What went into the
              <br className="d-none d-lg-block d-md-block" /> creation of the
              app?{" "}
            </h2>
          </div>
          <div className="col-lg-1 d-none d-lg-block"></div>
          <div className="col-lg-6 col-md-6 vinv-over">
            <p className="text-animate">
              For FUT Bank, we created a readily accessible banking application
              that gives both users and banks a seamless experience. A powerful
              scalable core banking application, it gave the bank the control it
              needed while making sure the user experience was absolutely smooth
              and simple.
            </p>
            <p className="text-animate">
              Right from the wire-frame design upwards, we worked on designing
              an accessible interface that is intuitive and easy to navigate.
            </p>
          </div>

          <div className="col-lg-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/fut-bank-video-1.mp4"
              alt="futbank"></video>
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-3.png"
              alt="intellect"
            />
          </div>
        </div>

        <div className="container vinveli-over">
          <div className="intellect-para">
            <p className="text-animate">
              As a scalable core banking application, Fut Bank app development
              includes iOS and Android versions, web applications, system
              emails, marketing materials, and more.
            </p>
          </div>
        </div>

        <div className="container text-animate intellect-bann-img">
          <img
            className="img-fluid"
            src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-4.png"
            alt="futbank-banner"
          />
        </div>

        <div className="container vinveli-over">
          <div className="row">
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-lg-4 col-md-6 vinv-over">
              <h2 className="text-animate">Easy Bill Payments</h2>
            </div>
            <div className="col-lg-1 d-none d-lg-block"></div>
            <div className="col-lg-6 col-md-6 vinv-over">
              <p className="text-animate">
                We designed the Fut Bank app such that it provided a unified
                payment experience with various payment options,
                reminders,multiple account integration, options to defer
                payments and more.
              </p>
            </div>

            <div className="col-lg-1"></div>
          </div>
        </div>

        <div className="container text-animate py-4">
          <img
            className="img-fluid"
            style={{ padding: "0 10px" }}
            src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-5.png"
            alt="intellect-banner"
          />
        </div>

        <div className="container pt-5 pb-md-5">
          <div className="row e-wallet-lg">
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Reachability by design</h5>
                <p className="text-animate">
                  The app was designed with reachability in mind. After studying
                  user behaviour, we made sure all primary actions were placed
                  in a comfortable reach area with just one finger and secondary
                  actions were placed little further. Important information was
                  always displayed at the top.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-6.png"
                alt="fut-bank-banner"
              />
            </div>
          </div>
        </div>

        <div className="container text-animate">
          <video
            loop
            autoPlay
            muted
            className="img-fluid"
            // style={{ padding: "0 10px" }}
            src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/fut-bank-video-2.mp4"
            alt="intellect-banner"></video>
        </div>

        <div className="container">
          <div className="row e-wallet-lg flyet-card-reverse pt-5 py-md-5">
            <div className="col-lg-6 col-md-6 text-animate">
              <img
                className="img-fluid fut-bank-sec-img"
                src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-8.png"
                alt="sunnybee-banner"
              />
            </div>
            <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
              <div className="d-block fut-bank-sec-cont">
                <h5 className="text-animate">Light & Dark modes</h5>
                <p className="text-animate">
                  Apart from functionality, we designed the app for aesthetic
                  appeal as well. We created a dark mode to protect user’s eyes
                  as well as battery life and made it simple enough to switch
                  between with just a swipe.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row intellect-img mb-md-5">
          <div className="col-lg-6 col-md-6">
            <video
              loop
              autoPlay
              muted
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://storage.googleapis.com/fingerprintsfuturewebsite/videos/fut-bank-video-3.mp4"
              alt="intellect"></video>
          </div>
          <div className="col-lg-6 col-md-6">
            <img
              className="img-fluid"
              style={{ padding: "10px" }}
              src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-10.png"
              alt="intellect"
            />
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-md-5">
        <div className="row e-wallet-lg">
          <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
            <div className="d-block fut-bank-sec-cont">
              <h5 className="text-animate">Beneficiary Bank accounts</h5>
              <p className="text-animate">
                To make the user experience even simpler, the app was designed
                to fetch the bank accounts details of user’s beneficiaries
                instantly with just a mobile number or a social media account.
                Users could then perform any inter or intra bank payments
                quickly and easily.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 text-animate">
            <img
              className="img-fluid fut-bank-sec-img"
              src="https://ik.imagekit.io/future/assets/tr:f-auto/fut-bank-img-11.png"
              alt="fut-bank-banner"
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-bb"></div>
      </div>

      <div>
        <div className="container sotia text-animate">
          <h2>See our thinking in action.</h2>
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={ewalletLatest.title}
                description={ewalletLatest.description}
                pageUrl={ewalletLatest.pageUrl}
                imageworkUrl={ewalletLatest.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={sunnybeeLatest.title}
                description={sunnybeeLatest.description}
                pageUrl={sunnybeeLatest.pageUrl}
                imageworkUrl={sunnybeeLatest.imageworkUrl}
              />
            </div>
            <div className="col-lg-4 col-md-12">
              <Seeourthinking
                title={flyet.title}
                description={flyet.description}
                pageUrl={flyet.pageUrl}
                imageworkUrl={flyet.imageworkUrl}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default FutbankLatest;
